import * as API from '@/api/api';
import { EntityMutationType } from '@/types/entity-mutation-type.enum';
import type { UserViewNotificationSeen } from '@/types/models/user-view-notification-seen.interface';
import { OptimisticEntityType } from '@/types/optimistic-entity-type.enum';
import { WebSocketMessageType } from '@/types/web-socket-message-type.enum';
import { optimisticDateISOString, optimisticId } from '@/utils/optimistic.utils';
import { defineStore } from 'pinia';
import { useWebSocketsStore } from '../web-sockets/web-sockets.store';
import type {
  UserViewNotificationSeenStoreActions,
  UserViewNotificationSeenStoreGetters,
  UserViewNotificationSeenStoreState,
} from './user-view-notification-seen-store.types';

export const useUserViewNotificationSeenStore = defineStore<
  'userViewNotificationSeen',
  UserViewNotificationSeenStoreState,
  UserViewNotificationSeenStoreGetters,
  UserViewNotificationSeenStoreActions
>('userViewNotificationSeen', {
  state: () => ({
    userViewNotificationsSeenIdsByViewId: {},
    userViewNotificationsSeenIdsLoadingByViewId: {},
    userViewNotificationsSeenIdsLoadedByViewId: {},
  }),
  getters: {
    userViewNotificationsSeenByViewId(): Partial<Record<string, UserViewNotificationSeen[]>> {
      const webSocketsStore = useWebSocketsStore();

      return Object.fromEntries(
        Object.entries(this.userViewNotificationsSeenIdsByViewId).map(([viewId, notificationsSeenIds]) => [
          viewId,
          [...notificationsSeenIds!]
            .filter((commentSeenId) => webSocketsStore.userViewNotificationSeenById[commentSeenId])
            .map((commentSeenId) => webSocketsStore.userViewNotificationSeenById[commentSeenId]!),
        ]),
      );
    },
  },
  actions: {
    processEntityMutation(userId, userEmail, entityMutation): void {
      switch (entityMutation.mutation_type) {
        case EntityMutationType.USER_VIEW_NOTIFICATION_SEEN_CREATED:
          if (!this.userViewNotificationsSeenIdsByViewId[entityMutation.entity.view_id]) {
            this.userViewNotificationsSeenIdsByViewId[entityMutation.entity.view_id] = new Set([
              entityMutation.entity.id,
            ]);
          } else {
            this.userViewNotificationsSeenIdsByViewId[entityMutation.entity.view_id]!.add(entityMutation.entity.id);
          }

          this.userViewNotificationsSeenIdsByViewId[entityMutation.entity.view_id]!.delete(
            optimisticId(OptimisticEntityType.USER_VIEW_NOTIFICATION_SEEN, {
              notificationId: entityMutation.entity.notification_id,
            }),
          );
          break;
      }
    },

    async createUserViewNotificationSeen(userId, viewId, notificationId): Promise<void> {
      const existing = this.userViewNotificationsSeenByViewId[viewId]?.some(
        ({ notification_id }) => notification_id === notificationId,
      );

      if (existing) {
        return;
      }

      const webSocketsStore = useWebSocketsStore();

      const optimistic_notification_seen: UserViewNotificationSeen = {
        id: optimisticId(OptimisticEntityType.USER_VIEW_NOTIFICATION_SEEN, { notificationId }),
        user_id: userId,
        view_id: viewId,
        notification_id: notificationId,
        seen_at: optimisticDateISOString(),
      };

      webSocketsStore.userViewNotificationSeenById[optimistic_notification_seen.id] = optimistic_notification_seen;

      if (!this.userViewNotificationsSeenIdsByViewId[viewId]) {
        this.userViewNotificationsSeenIdsByViewId[viewId] = new Set([optimistic_notification_seen.id]);
      } else {
        this.userViewNotificationsSeenIdsByViewId[viewId]!.add(optimistic_notification_seen.id);
      }

      webSocketsStore.sendMessage({
        type: WebSocketMessageType.CREATE_USER_VIEW_NOTIFICATION_SEEN,
        entity: {
          view_id: viewId,
          notification_id: notificationId,
        },
      });
    },

    async fetchUserViewNotificationsSeen(viewId): Promise<void> {
      if (
        this.userViewNotificationsSeenIdsLoadedByViewId[viewId] ||
        this.userViewNotificationsSeenIdsLoadingByViewId[viewId]
      ) {
        return;
      }

      const webSocketsStore = useWebSocketsStore();

      this.userViewNotificationsSeenIdsLoadingByViewId[viewId] = true;

      const userViewNotificationsSeen = await API.getUserViewNotificationsSeen(viewId);

      this.userViewNotificationsSeenIdsByViewId[viewId] = new Set(userViewNotificationsSeen.map(({ id }) => id));
      webSocketsStore.updateUserViewNotificationSeenById(userViewNotificationsSeen);

      this.userViewNotificationsSeenIdsLoadingByViewId[viewId] = false;
      this.userViewNotificationsSeenIdsLoadedByViewId[viewId] = true;
    },
  },
});
