import * as API from '@/api/api';
import { EntityMutationType } from '@/types/entity-mutation-type.enum';
import type { UserViewCommentReaction } from '@/types/models/user-view-comment-reaction.interface';
import type { UserViewComment } from '@/types/models/user-view-comment.interface';
import { OptimisticEntityType } from '@/types/optimistic-entity-type.enum';
import { optimisticDateISOString, optimisticId } from '@/utils/optimistic.utils';
import { defineStore } from 'pinia';
import { useWebSocketsStore } from '../web-sockets/web-sockets.store';
import type {
  UserViewCommentStoreActions,
  UserViewCommentStoreGetters,
  UserViewCommentStoreState,
} from './user-view-comment-store.types';

export const useUserViewCommentStore = defineStore<
  'userViewComment',
  UserViewCommentStoreState,
  UserViewCommentStoreGetters,
  UserViewCommentStoreActions
>('userViewComment', {
  state: () => ({
    loadingByUserViewCommentId: {},
    userViewCommentsIdsBySectionId: {},
    userViewCommentsIdsLoadingBySectionId: {},
    userViewCommentsIdsLoadedBySectionId: {},
  }),
  getters: {
    userViewCommentsBySectionId(): Partial<Record<string, UserViewComment[]>> {
      const webSocketsStore = useWebSocketsStore();

      return Object.fromEntries(
        Object.entries(this.userViewCommentsIdsBySectionId).map(([sectionId, commentsIds]) => [
          sectionId,
          [...commentsIds!]
            .filter((commentId) => webSocketsStore.userViewCommentById[commentId])
            .map((commentId) => webSocketsStore.userViewCommentById[commentId]!),
        ]),
      );
    },
  },
  actions: {
    processEntityMutation(userId, userEmail, entityMutation): void {
      switch (entityMutation.mutation_type) {
        case EntityMutationType.USER_VIEW_COMMENT_CREATED:
          if (!this.userViewCommentsIdsBySectionId[entityMutation.entity.section_id]) {
            this.userViewCommentsIdsBySectionId[entityMutation.entity.section_id] = new Set([entityMutation.entity.id]);
          } else {
            this.userViewCommentsIdsBySectionId[entityMutation.entity.section_id]!.add(entityMutation.entity.id);
          }
          break;

        case EntityMutationType.USER_VIEW_COMMENT_DELETED:
          if (!this.userViewCommentsIdsBySectionId[entityMutation.section_id]) {
            return;
          }

          this.userViewCommentsIdsBySectionId[entityMutation.section_id]!.delete(entityMutation.entity_id);
          break;
      }
    },

    async createUserViewComment({ viewId, authorId, parentId, sectionId, comment }): Promise<void> {
      const webSocketsStore = useWebSocketsStore();

      const optimistic_comment: UserViewComment = {
        id: optimisticId(OptimisticEntityType.USER_VIEW_COMMENT),
        author_id: authorId,
        view_id: viewId,
        parent_id: parentId,
        section_id: sectionId,
        comment,
        reactions: [] as UserViewCommentReaction[],
        created_at: optimisticDateISOString(),
        updated_at: optimisticDateISOString(),
        comment_updated_at: optimisticDateISOString(),
      };

      webSocketsStore.userViewCommentById[optimistic_comment.id] = optimistic_comment;

      if (!this.userViewCommentsIdsBySectionId[sectionId]) {
        this.userViewCommentsIdsBySectionId[sectionId] = new Set([optimistic_comment.id]);
      } else {
        this.userViewCommentsIdsBySectionId[sectionId]!.add(optimistic_comment.id);
      }

      try {
        const created = await API.createUserViewComment({
          view_id: viewId,
          parent_id: parentId,
          section_id: sectionId,
          comment,
        });

        webSocketsStore.userViewCommentById[created.id] = created;
        this.userViewCommentsIdsBySectionId[sectionId]!.add(created.id);
      } finally {
        this.userViewCommentsIdsBySectionId[sectionId]!.delete(optimistic_comment.id);
        delete webSocketsStore.userViewCommentById[optimistic_comment.id];
      }
    },

    async updateUserViewComment({ commentId, comment, reactions }): Promise<void> {
      if (this.loadingByUserViewCommentId[commentId]) {
        return;
      }

      const webSocketsStore = useWebSocketsStore();

      const original = webSocketsStore.userViewCommentById[commentId];
      const optimistic: UserViewComment = {
        ...original!,
        ...(comment ? { comment, comment_updated_at: optimisticDateISOString() } : {}),
        ...(reactions ? { reactions } : {}),
        updated_at: optimisticDateISOString(),
      };

      webSocketsStore.userViewCommentById[commentId] = optimistic;

      this.loadingByUserViewCommentId[commentId] = true;

      try {
        const userViewComment = await API.updateUserViewComment({
          comment_id: commentId,
          comment,
          reactions,
        });

        webSocketsStore.userViewCommentById[commentId] = userViewComment;
      } catch (err) {
        webSocketsStore.userViewCommentById[commentId] = original;
        throw err;
      }

      this.loadingByUserViewCommentId[commentId] = false;
    },

    async deleteUserViewComment(viewId, sectionId, commentId): Promise<void> {
      if (this.loadingByUserViewCommentId[commentId]) {
        return;
      }

      const webSocketsStore = useWebSocketsStore();

      this.loadingByUserViewCommentId[commentId] = true;

      await API.deleteUserViewComment(viewId, sectionId, commentId);

      this.userViewCommentsIdsBySectionId[sectionId]!.delete(commentId);
      delete webSocketsStore.userViewCommentById[commentId];

      this.loadingByUserViewCommentId[commentId] = false;
    },

    async fetchUserViewComments(viewId, sectionId): Promise<void> {
      if (
        this.userViewCommentsIdsLoadedBySectionId[sectionId] ||
        this.userViewCommentsIdsLoadingBySectionId[sectionId]
      ) {
        return;
      }

      const webSocketsStore = useWebSocketsStore();

      this.userViewCommentsIdsLoadingBySectionId[sectionId] = true;

      const userViewComments = await API.getUserViewComments(viewId, sectionId);

      this.userViewCommentsIdsBySectionId[sectionId] = new Set(userViewComments.map(({ id }) => id));
      webSocketsStore.updateUserViewCommentById(userViewComments);

      this.userViewCommentsIdsLoadingBySectionId[sectionId] = false;
      this.userViewCommentsIdsLoadedBySectionId[sectionId] = true;
    },
  },
});
