import type { IconType } from '@/components/AppIcons';
import type { QueryParamsValues } from '@/types/query-params-values.interface';

export type Routes = {
  signIn: Route;
  marketSelection: Route;
  range: Route;
  projects: Route;
  about: Route;
  notAuthorized: Route;
  loggedOut: Route;
};

export type Route = {
  name: string;
  path: string;
  isActive: boolean;
  icon?: IconType;
  isChildrenRouteActive?: boolean;
  defaultQueryParamsValues?: Partial<QueryParamsValues>;
};

export enum RoutePath {
  SIGN_IN = '/',
  MARKET_SELECTION = '/market-selection',
  RANGE = '/range',
  ABOUT = '/about',
  PROJECTS = '/projects',
  NOT_AUTHORIZED = '/not-authorized',
  SIGNED_OUT = '/logged-out',
}
