import * as API from '@/api/api';
import { EntityMutationType } from '@/types/entity-mutation-type.enum';
import type { UserViewNotificationDetails } from '@/types/models/user-view-notification-details.interface';
import { defineStore } from 'pinia';
import { useWebSocketsStore } from '../web-sockets/web-sockets.store';
import type {
  UserViewNotificationStoreActions,
  UserViewNotificationStoreGetters,
  UserViewNotificationStoreState,
} from './user-view-notification-store.types';

export const useUserViewNotificationStore = defineStore<
  'userViewNotification',
  UserViewNotificationStoreState,
  UserViewNotificationStoreGetters,
  UserViewNotificationStoreActions
>('userViewNotification', {
  state: () => ({
    userViewNotificationsIdsByViewId: {},
    userViewNotificationsIdsLoadingByViewId: {},
    userViewNotificationsIdsLoadedByViewId: {},
  }),
  getters: {
    userViewNotificationsByViewId(): Partial<Record<string, UserViewNotificationDetails[]>> {
      const webSocketsStore = useWebSocketsStore();

      return Object.fromEntries(
        Object.entries(this.userViewNotificationsIdsByViewId).map(([viewId, notificationsIds]) => [
          viewId,
          [...notificationsIds!]
            .filter((notificationId) => webSocketsStore.userViewNotificationById[notificationId])
            .map((notificationId) => webSocketsStore.userViewNotificationById[notificationId]!),
        ]),
      );
    },
  },
  actions: {
    processEntityMutation(userId, userEmail, entityMutation): void {
      switch (entityMutation.mutation_type) {
        case EntityMutationType.USER_VIEW_NOTIFICATION_CREATED:
          if (!this.userViewNotificationsIdsByViewId[entityMutation.entity.view_id]) {
            this.userViewNotificationsIdsByViewId[entityMutation.entity.view_id] = new Set([entityMutation.entity.id]);
          } else {
            this.userViewNotificationsIdsByViewId[entityMutation.entity.view_id]!.add(entityMutation.entity.id);
          }
          break;

        case EntityMutationType.USER_VIEW_NOTIFICATION_DELETED:
          if (!this.userViewNotificationsIdsByViewId[entityMutation.view_id]) {
            return;
          }

          Object.values(this.userViewNotificationsByViewId[entityMutation.view_id]!)
            .filter((notification) => {
              let condition = true;

              if (entityMutation.entity.comment_id) {
                condition &&= notification.comment?.id === entityMutation.entity.comment_id;
              }

              if (entityMutation.entity.user_id) {
                condition &&= notification.comment_reaction_user_id === entityMutation.entity.user_id;
              }

              if (entityMutation.entity.emoji) {
                condition &&= notification.comment_reaction_emoji === entityMutation.entity.emoji;
              }

              return condition;
            })
            .forEach(({ id }) => {
              this.userViewNotificationsIdsByViewId[entityMutation.view_id]!.delete(id);
            });

          break;
      }
    },

    async fetchUserViewNotifications(viewId): Promise<void> {
      if (this.userViewNotificationsIdsLoadedByViewId[viewId] || this.userViewNotificationsIdsLoadingByViewId[viewId]) {
        return;
      }

      const webSocketsStore = useWebSocketsStore();

      this.userViewNotificationsIdsLoadingByViewId[viewId] = true;

      const userViewNotifications = await API.getUserViewNotifications(viewId);

      this.userViewNotificationsIdsByViewId[viewId] = new Set(userViewNotifications.map(({ id }) => id));
      webSocketsStore.updateUserViewNotificationById(userViewNotifications);

      this.userViewNotificationsIdsLoadingByViewId[viewId] = false;
      this.userViewNotificationsIdsLoadedByViewId[viewId] = true;
    },
  },
});
