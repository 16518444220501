type FetchResource = string | Request | URL | RequestInfo;
type FetchConfig = RequestInit | undefined;
type OnRequest = (resource: FetchResource, config: FetchConfig) => void;
type OnResponse = (response: Response, resource: FetchResource, config: FetchConfig) => void;

export const monkeyPatchFetch = ({ onRequest, onResponse }: { onRequest?: OnRequest; onResponse?: OnResponse }) => {
  const { fetch: originalFetch } = window;

  window.fetch = async (...args) => {
    const [resource, config] = args as [FetchResource, FetchConfig];

    onRequest?.(resource, config);

    const response = await originalFetch(resource, config);

    // Pass the response, resource, and config to the onResponse callback
    onResponse?.(response, resource, config);

    return response;
  };
};
