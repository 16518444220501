import { MenuAboutSections } from '@/components/AppMenuAbout/AppMenuAbout.types';
import { useI18n } from '@/hooks/use-i18n.hook';
import { GroupOrderBy } from '@/types/group-order-by.enum';
import { Grouping } from '@/types/grouping.enum';
import { OrderDirection } from '@/types/order-direction.enum';
import { ProjectStep } from '@/types/project-step.enum';
import { MyProjectsPageTabView, RangePageTabView } from '@/types/tab-views.type';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { RoutePath, type Routes } from './types';

export const useRoutes = () => {
  const currentRoute = useRoute();
  const { translateKey } = useI18n();

  return computed<Routes>(() => ({
    signIn: {
      name: translateKey('routes.signIn'),
      path: RoutePath.SIGN_IN,
      isActive: currentRoute.matched.some((route) => route.path === RoutePath.SIGN_IN),
    },
    marketSelection: {
      name: translateKey('routes.marketSelection'),
      path: RoutePath.MARKET_SELECTION,
      isActive: currentRoute.matched.some((route) => route.path === RoutePath.MARKET_SELECTION),
    },
    range: {
      name: translateKey('routes.range'),
      path: RoutePath.RANGE,
      icon: 'DataChartIcon',
      isActive: currentRoute.matched.some((route) => route.path === RoutePath.RANGE),
      isChildrenRouteActive: currentRoute.matched.some((route) => route.path === `${RoutePath.RANGE}/:viewId?`),
      defaultQueryParamsValues: {
        grouping: Grouping.HFB,
        groupingPath: [Grouping.HFB],
        groupOrderBy: GroupOrderBy.TOTAL_ARTICLES,
        groupOrderDirection: OrderDirection.DESC,
        fiscalYear: 'FY24',
        rangePageTabView: RangePageTabView.RANGE_GRAPH,
        projectStep: ProjectStep.FIND_RANGE_RECOMMENDATIONS,
      },
    },
    projects: {
      name: translateKey('routes.projects'),
      path: RoutePath.PROJECTS,
      icon: 'PersonIcon',
      isActive: currentRoute.matched.some((route) => route.path === RoutePath.PROJECTS),
      isChildrenRouteActive: currentRoute.matched.some((route) => route.path === `${RoutePath.PROJECTS}/:id?`),
      defaultQueryParamsValues: {
        grouping: Grouping.HFB,
        groupingPath: [Grouping.HFB],
        groupOrderBy: GroupOrderBy.TOTAL_ARTICLES,
        groupOrderDirection: OrderDirection.DESC,
        fiscalYear: 'FY24',
        rangePageTabView: RangePageTabView.RANGE_GRAPH,
        projectStep: ProjectStep.FIND_RANGE_RECOMMENDATIONS,
        myProjectsPageTabView: MyProjectsPageTabView.RECENT_PROJECTS,
      },
    },
    about: {
      name: translateKey('routes.about'),
      path: `${RoutePath.ABOUT}/${MenuAboutSections.FIND_TAIL}`,
      icon: 'InformationIcon',
      isActive: currentRoute.matched.some((route) => route.path === RoutePath.ABOUT),
      isChildrenRouteActive: currentRoute.matched.some((route) => route.path === `${RoutePath.ABOUT}/:id?`),
    },
    notAuthorized: {
      name: translateKey('routes.notAuthorized'),
      path: RoutePath.NOT_AUTHORIZED,
      isActive: currentRoute.matched.some((route) => route.path === RoutePath.NOT_AUTHORIZED),
    },
    loggedOut: {
      name: translateKey('routes.loggedOut'),
      path: RoutePath.SIGNED_OUT,
      isActive: currentRoute.matched.some((route) => route.path === RoutePath.SIGNED_OUT),
    },
  }));
};
