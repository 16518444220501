import * as API from '@/api/api';
import { useAuth0 } from '@auth0/auth0-vue';
import { defineStore } from 'pinia';
import type { RetailUnitCodesStoreActions, RetailUnitCodesStoreState } from './retail-unit-codes-store.types';

export const useRetailUnitCodesStore = defineStore<
  'retailUnitCodesStore',
  RetailUnitCodesStoreState,
  Record<string, never>,
  RetailUnitCodesStoreActions
>('retailUnitCodesStore', {
  state: () => ({
    retailUnitCodes: [],
    retailUnitCodesLoading: false,
    retailUnitCodesLoaded: false,
  }),

  actions: {
    async fetchRetailUnitCodes(): Promise<void> {
      if (this.retailUnitCodesLoaded || this.retailUnitCodesLoading) {
        return;
      }

      this.retailUnitCodesLoading = true;

      const { getAccessTokenSilently } = useAuth0();
      const accessToken = await getAccessTokenSilently();

      this.retailUnitCodes = await API.getRetailUnitCodes(accessToken);

      this.retailUnitCodesLoading = false;
      this.retailUnitCodesLoaded = true;
    },
  },
});
