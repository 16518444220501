import { watchEffect, type Ref } from 'vue';

export const waitForLoadingToComplete = (isLoading: Ref<boolean>): Promise<void> => {
  return new Promise<void>((resolve) => {
    // Use `watchEffect` to track changes to `isLoading`
    watchEffect((onInvalidate) => {
      // When `isLoading` becomes `false`, resolve the promise
      if (!isLoading.value) {
        resolve();
      }
      // Cleanup watcher when it resolves
      onInvalidate(() => resolve());
    });
  });
};
