import { useRedirect } from '@/hooks/use-redirect.hook';
import { useRetailUnitCodesStore } from '@/store/retail-unit-codes/retail-unit-codes.store';
import { QueryParamType } from '@/types/query-param-type.enum';
import { convertToLocationQuery } from '@/utils/query-params.utils';
import { useAuth0 } from '@auth0/auth0-vue';
import type { NavigationGuard } from 'vue-router';
import { useRoutes } from './routes';
import { RoutePath, type Routes } from './types';
import { waitForLoadingToComplete } from './utils';

export const isAuthenticatedGuard: NavigationGuard = async (to, from, next) => {
  const { isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  await waitForLoadingToComplete(isLoading);

  let isAuthenticated: boolean;

  try {
    await getAccessTokenSilently();
    isAuthenticated = true;
  } catch (err) {
    isAuthenticated = false;
  }

  const { setRedirect } = useRedirect();

  if (isAuthenticated) {
    return next();
  }

  setRedirect({ path: to.path, query: to.query });

  await loginWithRedirect();

  next();
};

export const isUnauthenticatedGuard: NavigationGuard = async (to, from, next) => {
  const { isLoading, getAccessTokenSilently } = useAuth0();

  await waitForLoadingToComplete(isLoading);

  let isAuthenticated: boolean;

  try {
    await getAccessTokenSilently();
    isAuthenticated = true;
  } catch (err) {
    isAuthenticated = false;
  }

  const { getRedirect, clearRedirect } = useRedirect();

  if (isAuthenticated) {
    const redirect = getRedirect();

    if (redirect) {
      clearRedirect();
      return next(redirect);
    }

    return next({ path: RoutePath.MARKET_SELECTION });
  }

  next();
};

export const defaultQueryParamsGuard: (route: keyof Routes) => NavigationGuard = (route) => async (to, from, next) => {
  const routes = useRoutes();
  const defaultParams = convertToLocationQuery(routes.value[route].defaultQueryParamsValues ?? {});

  const query = {
    ...defaultParams,
    ...to.query,
  };

  const needsUpdate = Object.keys(defaultParams).some((key) => to.query[key] !== query[key]);

  if (!needsUpdate) {
    return next();
  }

  next({
    path: to.path,
    query,
  });
};

export const retailUnitCodeGuard: NavigationGuard = async (to, from, next): Promise<void> => {
  const retailUnitCodesStore = useRetailUnitCodesStore();

  await retailUnitCodesStore.fetchRetailUnitCodes();

  const retailUnitCode = to.query[QueryParamType.RETAIL_UNIT_CODE] as string | undefined;

  if (retailUnitCode && retailUnitCodesStore.retailUnitCodes.includes(retailUnitCode)) {
    return next();
  }

  const { setRedirect, getRedirect, clearRedirect } = useRedirect();

  // If there is access to only one market, set it as default
  if (retailUnitCodesStore.retailUnitCodes.length === 1) {
    const redirect = getRedirect();

    if (redirect) {
      clearRedirect();

      return next({
        path: redirect.path,
        query: {
          ...redirect.query,
          [QueryParamType.RETAIL_UNIT_CODE]: retailUnitCodesStore.retailUnitCodes[0],
        },
      });
    }

    return next({
      path: RoutePath.RANGE,
      query: { [QueryParamType.RETAIL_UNIT_CODE]: retailUnitCodesStore.retailUnitCodes[0] },
    });
  }

  setRedirect({ path: to.path, query: to.query });

  next({ path: RoutePath.MARKET_SELECTION });
};
