import type { GroupOrderBy } from '@/types/group-order-by.enum';
import type { Grouping } from '@/types/grouping.enum';
import type { OrderDirection } from '@/types/order-direction.enum';
import type { ProjectStep } from '@/types/project-step.enum';
import { QueryParamType } from '@/types/query-param-type.enum';
import type { QueryParamsValues } from '@/types/query-params-values.interface';
import type { MyProjectsPageTabView, RangePageTabView } from '@/types/tab-views.type';
import type { LocationQuery, LocationQueryValue } from 'vue-router';

export const serializeQueryParamValues = (values: string[]): string => {
  return values.join('][');
};

export const deserializeQueryParamValues = (serializedValues: string | null): string[] => {
  return serializedValues ? serializedValues.split('][') : [];
};

export const convertToQueryParamsValues = (queryParams: LocationQuery): QueryParamsValues => {
  const retailUnitCode = queryParams[QueryParamType.RETAIL_UNIT_CODE] as LocationQueryValue;
  const fiscalYear = queryParams[QueryParamType.FISCAL_YEAR] as LocationQueryValue;
  const grouping = queryParams[QueryParamType.GROUPING] as LocationQueryValue;
  const groupingPath = queryParams[QueryParamType.GROUPING_PATH] as LocationQueryValue;
  const groupNoPath = queryParams[QueryParamType.GROUP_NO_PATH] as LocationQueryValue;
  const groupingOfAppliedKPIs = queryParams[QueryParamType.GROUPING_OF_APPLIED_KPIS] as LocationQueryValue;
  const groupNoOfAppliedKPIs = queryParams[QueryParamType.GROUP_NO_OF_APPLIED_KPIS] as LocationQueryValue;
  const confirmationForAppliedKPIs = queryParams[QueryParamType.CONFIRMATION_FOR_APPLIED_KPIS] as LocationQueryValue;
  const groupOrderBy = queryParams[QueryParamType.GROUP_ORDER_BY] as LocationQueryValue;
  const groupOrderDirection = queryParams[QueryParamType.GROUP_ORDER_DIRECTION] as LocationQueryValue;
  const rangePageTabView = queryParams[QueryParamType.RANGE_PAGE_TAB_VIEW] as LocationQueryValue;
  const myProjectsPageTabView = queryParams[QueryParamType.MY_PROJECTS_PAGE_TAB_VIEW] as LocationQueryValue;
  const probabilityOfBuyingMin = queryParams[QueryParamType.PROBABILITY_OF_BUYING_MIN] as LocationQueryValue;
  const probabilityOfBuyingMax = queryParams[QueryParamType.PROBABILITY_OF_BUYING_MAX] as LocationQueryValue;
  const revenuePerReceiptMin = queryParams[QueryParamType.REVENUE_PER_RECEIPT_MIN] as LocationQueryValue;
  const revenuePerReceiptMax = queryParams[QueryParamType.REVENUE_PER_RECEIPT_MAX] as LocationQueryValue;
  const quantityGrowthRateMin = queryParams[QueryParamType.QUANTITY_GROWTH_RATE_MIN] as LocationQueryValue;
  const quantityGrowthRateMax = queryParams[QueryParamType.QUANTITY_GROWTH_RATE_MAX] as LocationQueryValue;
  const quantityPerReceiptMin = queryParams[QueryParamType.QUANTITY_PER_RECEIPT_MIN] as LocationQueryValue;
  const quantityPerReceiptMax = queryParams[QueryParamType.QUANTITY_PER_RECEIPT_MAX] as LocationQueryValue;
  const fivePercentRankingTail = queryParams[QueryParamType.FIVE_PERCENT_RANKING_TAIL] as LocationQueryValue;
  const tenPercentRankingTail = queryParams[QueryParamType.TEN_PERCENT_RANKING_TAIL] as LocationQueryValue;
  const autoDetectRankingTail = queryParams[QueryParamType.AUTO_DETECT_RANKING_TAIL] as LocationQueryValue;
  const projectStep = queryParams[QueryParamType.PROJECT_STEP] as LocationQueryValue;

  return {
    retailUnitCode: retailUnitCode ?? null,
    fiscalYear: fiscalYear ?? null,
    grouping: grouping ? (grouping as Grouping) : null,
    groupingPath: groupingPath ? (deserializeQueryParamValues(groupingPath) as Grouping[]) : null,
    groupNoPath: groupNoPath ? deserializeQueryParamValues(groupNoPath) : null,
    groupingOfAppliedKPIs: groupingOfAppliedKPIs ? (groupingOfAppliedKPIs as Grouping) : null,
    groupNoOfAppliedKPIs: groupNoOfAppliedKPIs ?? null,
    confirmationForAppliedKPIs: confirmationForAppliedKPIs ? confirmationForAppliedKPIs === 'true' : null,
    groupOrderBy: groupOrderBy ? (groupOrderBy as GroupOrderBy) : null,
    groupOrderDirection: groupOrderDirection ? (groupOrderDirection as OrderDirection) : null,
    rangePageTabView: rangePageTabView ? (+rangePageTabView as RangePageTabView) : null,
    myProjectsPageTabView: myProjectsPageTabView ? (+myProjectsPageTabView as MyProjectsPageTabView) : null,
    probabilityOfBuyingMin: probabilityOfBuyingMin ? +probabilityOfBuyingMin : null,
    probabilityOfBuyingMax: probabilityOfBuyingMax ? +probabilityOfBuyingMax : null,
    revenuePerReceiptMin: revenuePerReceiptMin ? +revenuePerReceiptMin : null,
    revenuePerReceiptMax: revenuePerReceiptMax ? +revenuePerReceiptMax : null,
    quantityGrowthRateMin: quantityGrowthRateMin ? +quantityGrowthRateMin : null,
    quantityGrowthRateMax: quantityGrowthRateMax ? +quantityGrowthRateMax : null,
    quantityPerReceiptMin: quantityPerReceiptMin ? +quantityPerReceiptMin : null,
    quantityPerReceiptMax: quantityPerReceiptMax ? +quantityPerReceiptMax : null,
    fivePercentRankingTail: fivePercentRankingTail ? fivePercentRankingTail === 'true' : null,
    tenPercentRankingTail: tenPercentRankingTail ? tenPercentRankingTail === 'true' : null,
    autoDetectRankingTail: autoDetectRankingTail ? autoDetectRankingTail === 'true' : null,
    projectStep: projectStep ? (projectStep as ProjectStep) : null,
  };
};

export const convertToLocationQuery = (queryParamsValues: Partial<QueryParamsValues>): LocationQuery => {
  return {
    ...(queryParamsValues.fiscalYear !== undefined
      ? {
          [QueryParamType.FISCAL_YEAR]: queryParamsValues.fiscalYear,
        }
      : {}),
    ...(queryParamsValues.retailUnitCode !== undefined
      ? {
          [QueryParamType.RETAIL_UNIT_CODE]: queryParamsValues.retailUnitCode,
        }
      : {}),
    ...(queryParamsValues.grouping !== undefined
      ? {
          [QueryParamType.GROUPING]: queryParamsValues.grouping,
        }
      : {}),
    ...(queryParamsValues.groupingPath !== undefined
      ? {
          [QueryParamType.GROUPING_PATH]: queryParamsValues.groupingPath
            ? serializeQueryParamValues(queryParamsValues.groupingPath)
            : null,
        }
      : {}),
    ...(queryParamsValues.groupNoPath !== undefined
      ? {
          [QueryParamType.GROUP_NO_PATH]: queryParamsValues.groupNoPath
            ? serializeQueryParamValues(queryParamsValues.groupNoPath)
            : null,
        }
      : {}),
    ...(queryParamsValues.groupingOfAppliedKPIs !== undefined
      ? {
          [QueryParamType.GROUPING_OF_APPLIED_KPIS]: queryParamsValues.groupingOfAppliedKPIs,
        }
      : {}),
    ...(queryParamsValues.groupNoOfAppliedKPIs !== undefined
      ? {
          [QueryParamType.GROUP_NO_OF_APPLIED_KPIS]: queryParamsValues.groupNoOfAppliedKPIs,
        }
      : {}),
    ...(queryParamsValues.confirmationForAppliedKPIs !== undefined
      ? {
          [QueryParamType.CONFIRMATION_FOR_APPLIED_KPIS]:
            queryParamsValues.confirmationForAppliedKPIs !== null
              ? queryParamsValues.confirmationForAppliedKPIs.toString()
              : null,
        }
      : {}),
    ...(queryParamsValues.groupOrderBy !== undefined
      ? {
          [QueryParamType.GROUP_ORDER_BY]: queryParamsValues.groupOrderBy,
        }
      : {}),
    ...(queryParamsValues.groupOrderDirection !== undefined
      ? {
          [QueryParamType.GROUP_ORDER_DIRECTION]: queryParamsValues.groupOrderDirection,
        }
      : {}),
    ...(queryParamsValues.rangePageTabView !== undefined
      ? {
          [QueryParamType.RANGE_PAGE_TAB_VIEW]:
            queryParamsValues.rangePageTabView !== null ? queryParamsValues.rangePageTabView.toString() : null,
        }
      : {}),
    ...(queryParamsValues.myProjectsPageTabView !== undefined
      ? {
          [QueryParamType.MY_PROJECTS_PAGE_TAB_VIEW]:
            queryParamsValues.myProjectsPageTabView !== null
              ? queryParamsValues.myProjectsPageTabView.toString()
              : null,
        }
      : {}),
    ...(queryParamsValues.probabilityOfBuyingMin !== undefined
      ? {
          [QueryParamType.PROBABILITY_OF_BUYING_MIN]:
            queryParamsValues.probabilityOfBuyingMin !== null
              ? queryParamsValues.probabilityOfBuyingMin.toString()
              : null,
        }
      : {}),
    ...(queryParamsValues.probabilityOfBuyingMax !== undefined
      ? {
          [QueryParamType.PROBABILITY_OF_BUYING_MAX]:
            queryParamsValues.probabilityOfBuyingMax !== null
              ? queryParamsValues.probabilityOfBuyingMax.toString()
              : null,
        }
      : {}),
    ...(queryParamsValues.revenuePerReceiptMin !== undefined
      ? {
          [QueryParamType.REVENUE_PER_RECEIPT_MIN]:
            queryParamsValues.revenuePerReceiptMin !== null ? queryParamsValues.revenuePerReceiptMin.toString() : null,
        }
      : {}),
    ...(queryParamsValues.revenuePerReceiptMax !== undefined
      ? {
          [QueryParamType.REVENUE_PER_RECEIPT_MAX]:
            queryParamsValues.revenuePerReceiptMax !== null ? queryParamsValues.revenuePerReceiptMax.toString() : null,
        }
      : {}),
    ...(queryParamsValues.quantityGrowthRateMin !== undefined
      ? {
          [QueryParamType.QUANTITY_GROWTH_RATE_MIN]:
            queryParamsValues.quantityGrowthRateMin !== null
              ? queryParamsValues.quantityGrowthRateMin.toString()
              : null,
        }
      : {}),
    ...(queryParamsValues.quantityGrowthRateMax !== undefined
      ? {
          [QueryParamType.QUANTITY_GROWTH_RATE_MAX]:
            queryParamsValues.quantityGrowthRateMax !== null
              ? queryParamsValues.quantityGrowthRateMax.toString()
              : null,
        }
      : {}),
    ...(queryParamsValues.quantityPerReceiptMin !== undefined
      ? {
          [QueryParamType.QUANTITY_PER_RECEIPT_MIN]:
            queryParamsValues.quantityPerReceiptMin !== null
              ? queryParamsValues.quantityPerReceiptMin.toString()
              : null,
        }
      : {}),
    ...(queryParamsValues.quantityPerReceiptMax !== undefined
      ? {
          [QueryParamType.QUANTITY_PER_RECEIPT_MAX]:
            queryParamsValues.quantityPerReceiptMax !== null
              ? queryParamsValues.quantityPerReceiptMax.toString()
              : null,
        }
      : {}),
    ...(queryParamsValues.fivePercentRankingTail !== undefined
      ? {
          [QueryParamType.FIVE_PERCENT_RANKING_TAIL]:
            queryParamsValues.fivePercentRankingTail !== null
              ? queryParamsValues.fivePercentRankingTail.toString()
              : null,
        }
      : {}),
    ...(queryParamsValues.tenPercentRankingTail !== undefined
      ? {
          [QueryParamType.TEN_PERCENT_RANKING_TAIL]:
            queryParamsValues.tenPercentRankingTail !== null
              ? queryParamsValues.tenPercentRankingTail.toString()
              : null,
        }
      : {}),
    ...(queryParamsValues.autoDetectRankingTail !== undefined
      ? {
          [QueryParamType.AUTO_DETECT_RANKING_TAIL]:
            queryParamsValues.autoDetectRankingTail !== null
              ? queryParamsValues.autoDetectRankingTail.toString()
              : null,
        }
      : {}),
    ...(queryParamsValues.projectStep !== undefined
      ? {
          [QueryParamType.PROJECT_STEP]: queryParamsValues.projectStep,
        }
      : {}),
  };
};

export const toQueryString = (queryParams: LocationQuery): string => {
  const withValues = Object.fromEntries(Object.entries(queryParams).filter(([, value]) => value));
  return new URLSearchParams(<Record<string, string>>withValues).toString();
};

export const fromQueryString = (queryString: string): LocationQuery => {
  return Object.fromEntries(new URLSearchParams(queryString).entries());
};
