import * as API from '@/api/api';
import { EntityMutationType } from '@/types/entity-mutation-type.enum';
import type { UserViewChangeHistory } from '@/types/models/user-view-change-history.interface';
import { defineStore } from 'pinia';
import { useWebSocketsStore } from '../web-sockets/web-sockets.store';
import type {
  UserViewChangeHistoryStoreActions,
  UserViewChangeHistoryStoreGetters,
  UserViewChangeHistoryStoreState,
} from './user-view-change-history-store.types';

export const useUserViewChangeHistoryStore = defineStore<
  'userViewChangeHistory',
  UserViewChangeHistoryStoreState,
  UserViewChangeHistoryStoreGetters,
  UserViewChangeHistoryStoreActions
>('userViewChangeHistory', {
  state: () => ({
    userViewChangeHistoriesIdsByViewId: {},
    userViewChangeHistoriesIdsLoadingByViewId: {},
    userViewChangeHistoriesIdsLoadedByViewId: {},
  }),
  getters: {
    userViewChangeHistoriesByViewId(): Partial<Record<string, UserViewChangeHistory[]>> {
      const webSocketsStore = useWebSocketsStore();

      return Object.fromEntries(
        Object.entries(this.userViewChangeHistoriesIdsByViewId).map(([viewId, changeHistoriesIds]) => [
          viewId,
          [...changeHistoriesIds!]
            .filter((changeHistoryId) => webSocketsStore.userViewChangeHistoryById[changeHistoryId])
            .map((changeHistoryId) => webSocketsStore.userViewChangeHistoryById[changeHistoryId]!),
        ]),
      );
    },
  },
  actions: {
    processEntityMutation(userId, userEmail, entityMutation): void {
      switch (entityMutation.mutation_type) {
        case EntityMutationType.USER_VIEW_CHANGE_HISTORY_CREATED:
          if (!this.userViewChangeHistoriesIdsByViewId[entityMutation.entity.view_id]) {
            this.userViewChangeHistoriesIdsByViewId[entityMutation.entity.view_id] = new Set([
              entityMutation.entity.id,
            ]);
          } else {
            this.userViewChangeHistoriesIdsByViewId[entityMutation.entity.view_id]!.add(entityMutation.entity.id);
          }

          break;
      }
    },

    async fetchUserViewChangeHistories(viewId): Promise<void> {
      if (
        this.userViewChangeHistoriesIdsLoadedByViewId[viewId] ||
        this.userViewChangeHistoriesIdsLoadingByViewId[viewId]
      ) {
        return;
      }

      const webSocketsStore = useWebSocketsStore();

      this.userViewChangeHistoriesIdsLoadingByViewId[viewId] = true;

      const userViewChangeHistories = await API.getUserViewChangeHistories(viewId);

      this.userViewChangeHistoriesIdsByViewId[viewId] = new Set(userViewChangeHistories.map(({ id }) => id));
      webSocketsStore.updateUserViewChangeHistoryById(userViewChangeHistories);

      this.userViewChangeHistoriesIdsLoadingByViewId[viewId] = false;
      this.userViewChangeHistoriesIdsLoadedByViewId[viewId] = true;
    },
  },
});
