import type { OptimisticEntityType } from '@/types/optimistic-entity-type.enum';
import { withTimezoneOffset } from './common.utils';

export const optimisticId = (type: OptimisticEntityType, payload?: Record<string, unknown>): string => {
  return `optimistic_${type}_${payload ? JSON.stringify(payload) : Date.now()}`;
};

export const isOptimistic = (id: string): boolean => {
  return id.startsWith('optimistic_');
};

export const optimisticDateISOString = (): string => {
  return withTimezoneOffset(new Date()).toISOString();
};
