import type { UserSettingsState } from './types/models/user-settings-state.interface';
import type { Option } from './types/option.interface';

export const JIRA_SERVICE_DESK_URL = 'https://jira.digital.ingka.com/servicedesk/customer/portal/424';
export const VIEW_OPTION_SEPARATOR = ' - ';

export const SCROLL_DEBOUNCE_MS = 30;
export const HEARTBEAT_DEBOUNCE_MS = 1000;
export const DEFAULT_SNACKBAR_DURATION = 8000;
export const NOT_AVAILABLE_PLACEHOLDER = 'N/A';

export const DEFAULT_USER_SETTINGS_STATE: UserSettingsState = {
  tail: {
    tableIncludedArticlesIds: [],
    tableExcludedArticlesIds: [],
    tablePinnedHeaders: [],
    tableHiddenHeaders: [],
    tableOrderedHeaders: [],
    tableArticleStatusValues: [],
    tableCCLValues: [],
    tableStyleGroupValues: [],
    tablePriceLevelValues: [],
    tableProductNameValues: [],
    tablePage: 1,
    tableItemsPerPage: 50,
    tableOrderBy: 'ranking_place',
    tableDesc: true,
    tableSearchColumn: 'Item_Number',
    tableSearchValue: '',
    tableColoring: {},
  },
  consolidate: {
    tableIncludedArticlesIds: [],
    tableExcludedArticlesIds: [],
    tablePinnedHeaders: [],
    tableHiddenHeaders: [],
    tableOrderedHeaders: [],
    tableArticleStatusValues: [],
    tableCCLValues: [],
    tableStyleGroupValues: [],
    tablePriceLevelValues: [],
    tableProductNameValues: [],
    tablePage: 1,
    tableItemsPerPage: 50,
    tableOrderBy: 'final_consolidate_ranking',
    tableDesc: true,
    tableSearchColumn: 'base_item_no',
    tableSearchValue: '',
    tableColoring: {},
  },
  conclusion: {
    tableIncludedArticlesIds: [],
    tableExcludedArticlesIds: [],
    tablePinnedHeaders: [],
    tableHiddenHeaders: [],
    tableOrderedHeaders: [],
    tableArticleStatusValues: [],
    tableCCLValues: [],
    tableStyleGroupValues: [],
    tablePriceLevelValues: [],
    tableProductNameValues: [],
    tablePage: 1,
    tableItemsPerPage: 50,
    tableOrderBy: 'article_ranking_place',
    tableDesc: true,
    tableSearchColumn: 'article_item_number',
    tableSearchValue: '',
    tableColoring: {},
  },
};

export const ALL_OPTION = {
  value: 'All',
  group_no: 'all',
  parent_group_no: null,
  hfb_no: null,
  pa_no: null,
  functional_area_name_no: null,
  consumer_function_name_no: null,
  product_function_name_no: null,
} satisfies Option<string>;
