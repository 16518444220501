import * as API from '@/api/api';
import { EntityMutationType } from '@/types/entity-mutation-type.enum';
import type { UserViewCommentSeen } from '@/types/models/user-view-comment-seen.interface';
import { OptimisticEntityType } from '@/types/optimistic-entity-type.enum';
import { WebSocketMessageType } from '@/types/web-socket-message-type.enum';
import { optimisticDateISOString, optimisticId } from '@/utils/optimistic.utils';
import { defineStore } from 'pinia';
import { useWebSocketsStore } from '../web-sockets/web-sockets.store';
import type {
  UserViewCommentSeenStoreActions,
  UserViewCommentSeenStoreGetters,
  UserViewCommentSeenStoreState,
} from './user-view-comment-seen-store.types';

export const useUserViewCommentSeenStore = defineStore<
  'userViewCommentSeen',
  UserViewCommentSeenStoreState,
  UserViewCommentSeenStoreGetters,
  UserViewCommentSeenStoreActions
>('userViewCommentSeen', {
  state: () => ({
    userViewCommentsSeenIdsBySectionId: {},
    userViewCommentsSeenIdsLoadingBySectionId: {},
    userViewCommentsSeenIdsLoadedBySectionId: {},
  }),
  getters: {
    userViewCommentsSeenBySectionId(): Partial<Record<string, UserViewCommentSeen[]>> {
      const webSocketsStore = useWebSocketsStore();

      return Object.fromEntries(
        Object.entries(this.userViewCommentsSeenIdsBySectionId).map(([sectionId, commentsSeenIds]) => [
          sectionId,
          [...commentsSeenIds!]
            .filter((commentSeenId) => webSocketsStore.userViewCommentSeenById[commentSeenId])
            .map((commentSeenId) => webSocketsStore.userViewCommentSeenById[commentSeenId]!),
        ]),
      );
    },
  },
  actions: {
    processEntityMutation(userId, userEmail, entityMutation): void {
      switch (entityMutation.mutation_type) {
        case EntityMutationType.USER_VIEW_COMMENT_SEEN_CREATED:
          if (!this.userViewCommentsSeenIdsBySectionId[entityMutation.entity.section_id]) {
            this.userViewCommentsSeenIdsBySectionId[entityMutation.entity.section_id] = new Set([
              entityMutation.entity.id,
            ]);
          } else {
            this.userViewCommentsSeenIdsBySectionId[entityMutation.entity.section_id]!.add(entityMutation.entity.id);
          }

          this.userViewCommentsSeenIdsBySectionId[entityMutation.entity.section_id]!.delete(
            optimisticId(OptimisticEntityType.USER_VIEW_COMMENT_SEEN, { commentId: entityMutation.entity.comment_id }),
          );
          break;
      }
    },

    async createUserViewCommentSeen(userId, viewId, sectionId, commentId): Promise<void> {
      const existing = this.userViewCommentsSeenBySectionId[sectionId]?.some(
        ({ comment_id }) => comment_id === commentId,
      );

      if (existing) {
        return;
      }

      const webSocketsStore = useWebSocketsStore();

      const optimistic_comment_seen: UserViewCommentSeen = {
        id: optimisticId(OptimisticEntityType.USER_VIEW_COMMENT_SEEN, { commentId }),
        user_id: userId,
        view_id: viewId,
        section_id: sectionId,
        comment_id: commentId,
        seen_at: optimisticDateISOString(),
      };

      webSocketsStore.userViewCommentSeenById[optimistic_comment_seen.id] = optimistic_comment_seen;

      if (!this.userViewCommentsSeenIdsBySectionId[sectionId]) {
        this.userViewCommentsSeenIdsBySectionId[sectionId] = new Set([optimistic_comment_seen.id]);
      } else {
        this.userViewCommentsSeenIdsBySectionId[sectionId]!.add(optimistic_comment_seen.id);
      }

      webSocketsStore.sendMessage({
        type: WebSocketMessageType.CREATE_USER_VIEW_COMMENT_SEEN,
        entity: {
          view_id: viewId,
          section_id: sectionId,
          comment_id: commentId,
        },
      });
    },

    async fetchUserViewCommentsSeen(viewId, sectionId): Promise<void> {
      if (
        this.userViewCommentsSeenIdsLoadedBySectionId[sectionId] ||
        this.userViewCommentsSeenIdsLoadingBySectionId[sectionId]
      ) {
        return;
      }

      const webSocketsStore = useWebSocketsStore();

      this.userViewCommentsSeenIdsLoadingBySectionId[sectionId] = true;

      const userViewCommentsSeen = await API.getUserViewCommentsSeen(viewId, sectionId);

      this.userViewCommentsSeenIdsBySectionId[sectionId] = new Set(userViewCommentsSeen.map(({ id }) => id));
      webSocketsStore.updateUserViewCommentSeenById(userViewCommentsSeen);

      this.userViewCommentsSeenIdsLoadingBySectionId[sectionId] = false;
      this.userViewCommentsSeenIdsLoadedBySectionId[sectionId] = true;
    },
  },
});
