import config from '@/config';
import { EntityMutationType } from '@/types/entity-mutation-type.enum';
import type { EntityMutation } from '@/types/entity-mutation.interface';
import { NodeEnv } from '@/types/node-env.enum';
import { OptimisticEntityType } from '@/types/optimistic-entity-type.enum';
import { WebSocketMessageType } from '@/types/web-socket-message-type.enum';
import { optimisticId } from '@/utils/optimistic.utils';
import { defineStore } from 'pinia';
import type { WebSocketsStoreActions, WebSocketsStoreState } from './web-sockets-store.types';

const constructWsUrl = (token: string): string => {
  const { protocol, host } = window.location;
  const wsProtocol = protocol === 'https:' ? 'wss:' : 'ws:';
  const wsUrl = `${process.env.NODE_ENV === NodeEnv.DEVELOPMENT ? config.wsUserEndpoint : `${wsProtocol}//${host}`}/api/v1/user/ws?token=${token}`;

  return wsUrl;
};

interface UpdatableEntity {
  updated_at: string;
}

const isGoingToCauseRaceConditions = (mutationEntity: UpdatableEntity, existingEntity?: UpdatableEntity): boolean => {
  return !!existingEntity && new Date(mutationEntity.updated_at) <= new Date(existingEntity.updated_at);
};

export const useWebSocketsStore = defineStore<
  'webSocketsStore',
  WebSocketsStoreState,
  Record<string, never>,
  WebSocketsStoreActions
>('webSocketsStore', {
  state: () => ({
    userSessionById: {},
    userDetailsByOwnerId: {},
    userDetailsByOwnerEmail: {},
    userViewById: {},
    userViewChangeHistoryById: {},
    userViewCommentById: {},
    userViewCommentSeenById: {},
    userViewNotificationById: {},
    userViewNotificationSeenById: {},
    webSocket: null,
  }),
  actions: {
    initWebSocketChannels(userId, userEmail, token, subscribers): void {
      this.webSocket = new WebSocket(constructWsUrl(token));

      this.webSocket.addEventListener('message', (event) => {
        const mutation: EntityMutation = JSON.parse(event.data);

        switch (mutation.mutation_type) {
          case EntityMutationType.USER_VIEW_CREATED:
            if (isGoingToCauseRaceConditions(mutation.entity, this.userViewById[mutation.entity.id])) {
              return;
            }

            this.userViewById[mutation.entity.id] = mutation.entity;
            break;

          case EntityMutationType.USER_VIEW_UPDATED:
            if (isGoingToCauseRaceConditions(mutation.entity, this.userViewById[mutation.entity.id])) {
              return;
            }

            this.userViewById[mutation.entity.id] = mutation.entity;
            break;

          case EntityMutationType.USER_VIEW_CHANGE_HISTORY_CREATED:
            this.userViewChangeHistoryById[mutation.entity.id] = mutation.entity;
            break;

          case EntityMutationType.USER_VIEW_COMMENT_CREATED:
            if (isGoingToCauseRaceConditions(mutation.entity, this.userViewById[mutation.entity.id])) {
              return;
            }

            this.userViewCommentById[mutation.entity.id] = mutation.entity;
            break;

          case EntityMutationType.USER_VIEW_COMMENT_UPDATED:
            if (isGoingToCauseRaceConditions(mutation.entity, this.userViewCommentById[mutation.entity.id])) {
              return;
            }

            this.userViewCommentById[mutation.entity.id] = mutation.entity;
            break;

          case EntityMutationType.USER_VIEW_COMMENT_DELETED:
            delete this.userViewCommentById[mutation.entity_id];
            break;

          case EntityMutationType.USER_VIEW_COMMENT_SEEN_CREATED:
            this.userViewCommentSeenById[mutation.entity.id] = mutation.entity;

            delete this.userViewCommentSeenById[
              optimisticId(OptimisticEntityType.USER_VIEW_COMMENT_SEEN, { commentId: mutation.entity.comment_id })
            ];
            break;

          case EntityMutationType.USER_VIEW_NOTIFICATION_CREATED:
            this.userViewNotificationById[mutation.entity.id] = mutation.entity;
            break;

          case EntityMutationType.USER_VIEW_NOTIFICATION_DELETED:
            Object.values(this.userViewNotificationById)
              .filter((notification) => {
                let condition = true;

                if (mutation.entity.comment_id) {
                  condition &&= notification!.comment?.id === mutation.entity.comment_id;
                }

                if (mutation.entity.user_id) {
                  condition &&= notification!.comment_reaction_user_id === mutation.entity.user_id;
                }

                if (mutation.entity.emoji) {
                  condition &&= notification!.comment_reaction_emoji === mutation.entity.emoji;
                }

                return condition;
              })
              .forEach((notification) => {
                delete this.userViewNotificationById[notification!.id];
              });
            break;

          case EntityMutationType.USER_VIEW_NOTIFICATION_SEEN_CREATED:
            this.userViewNotificationSeenById[mutation.entity.id] = mutation.entity;

            delete this.userViewNotificationSeenById[
              optimisticId(OptimisticEntityType.USER_VIEW_NOTIFICATION_SEEN, {
                notificationId: mutation.entity.notification_id,
              })
            ];
            break;

          case EntityMutationType.USER_SESSION_CREATED:
            if (isGoingToCauseRaceConditions(mutation.entity, this.userViewById[mutation.entity.id])) {
              return;
            }

            this.userSessionById[mutation.entity.id] = mutation.entity;
            break;

          case EntityMutationType.USER_SESSION_UPDATED:
            if (isGoingToCauseRaceConditions(mutation.entity, this.userSessionById[mutation.entity.id])) {
              return;
            }

            this.userSessionById[mutation.entity.id] = mutation.entity;
            break;

          case EntityMutationType.USER_SESSION_DELETED:
            delete this.userSessionById[mutation.entity_id];
            break;

          case EntityMutationType.USER_DETAILS_UPDATED:
            if (isGoingToCauseRaceConditions(mutation.entity, this.userDetailsByOwnerId[mutation.entity.owner_id])) {
              return;
            }

            this.userDetailsByOwnerId[mutation.entity.owner_id] = mutation.entity;
            this.userDetailsByOwnerEmail[mutation.entity.owner_email] = mutation.entity;
            break;
        }

        // Notify all subscribers
        subscribers.forEach((subscriber) => {
          subscriber.processEntityMutation(userId, userEmail, mutation);
        });
      });

      this.webSocket.addEventListener('open', () => {
        console.log('WebSocket connection opened');
        this.updateLastRoutePath(window.location.pathname);
      });

      this.webSocket.addEventListener('close', () => {
        console.log('WebSocket connection closed');
        this.initWebSocketChannels(userId, userEmail, token, subscribers);
      });
    },

    updateLastRoutePath(lastRoutePath): void {
      this.sendMessage({
        type: WebSocketMessageType.LAST_ROUTE_PATH_CHANGE,
        entity: {
          last_route_path: lastRoutePath,
        },
      });
    },

    updateEditingTable(editingTable): void {
      this.sendMessage({
        type: WebSocketMessageType.EDITING_TABLE_CHANGE,
        entity: {
          editing_table: editingTable,
        },
      });
    },

    sendMessage(message): void {
      if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
        this.webSocket.send(JSON.stringify(message));
      }
    },

    // Send a heartbeat "ping" message to the server
    sendHeartbeat(): void {
      if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
        this.webSocket.send('ping');
        console.log('Heartbeat sent');
      }
    },

    updateUserSessionById(userSessions): void {
      for (const userSession of userSessions) {
        this.userSessionById[userSession.id] = userSession;
      }
    },

    updateUsersDetails(usersDetails): void {
      for (const userDetails of usersDetails) {
        this.userDetailsByOwnerId[userDetails.owner_id] = userDetails;
        this.userDetailsByOwnerEmail[userDetails.owner_email] = userDetails;
      }
    },

    updateUserViewById(userViews): void {
      for (const userView of userViews) {
        this.userViewById[userView.id] = userView;
      }
    },

    updateUserViewChangeHistoryById(userViewChangeHistories): void {
      for (const userViewChangeHistory of userViewChangeHistories) {
        this.userViewChangeHistoryById[userViewChangeHistory.id] = userViewChangeHistory;
      }
    },

    updateUserViewCommentById(userViewComments): void {
      for (const userViewComment of userViewComments) {
        this.userViewCommentById[userViewComment.id] = userViewComment;
      }
    },

    updateUserViewCommentSeenById(userViewCommentsSeen): void {
      for (const userViewCommentSeen of userViewCommentsSeen) {
        this.userViewCommentSeenById[userViewCommentSeen.id] = userViewCommentSeen;
      }
    },

    updateUserViewNotificationById(userViewNotifications): void {
      for (const userViewNotification of userViewNotifications) {
        this.userViewNotificationById[userViewNotification.id] = userViewNotification;
      }
    },

    updateUserViewNotificationSeenById(userViewNotificationsSeen): void {
      for (const userViewNotificationSeen of userViewNotificationsSeen) {
        this.userViewNotificationSeenById[userViewNotificationSeen.id] = userViewNotificationSeen;
      }
    },
  },
});
