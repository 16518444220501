// Keeping the data visualization colors in once places

export const NEW_IN_RANGE_COLOR = '#0058A3';
export const EDS_COLOR = '#CC0008';
export const IN_RANGE_COLOR = '#E2D7CA';
export const TAIL_COLOR = '#F26A2F';
export const NON_TAIL_COLOR = '#E2D7CA';

export const useArticleStatusColors = () => {
  return () => {
    document.documentElement.style.setProperty('--new-in-range-color', NEW_IN_RANGE_COLOR);
    document.documentElement.style.setProperty('--eds-color', EDS_COLOR);
    document.documentElement.style.setProperty('--in-range-color', IN_RANGE_COLOR);
    document.documentElement.style.setProperty('--tail-color', TAIL_COLOR);
    document.documentElement.style.setProperty('--non-tail-color', NON_TAIL_COLOR);
  };
};
