import { buildQueryString } from '@/api/api.utils';
import config from '@/config';
import { useAPI } from '@/hooks/use-api.hook';
import type { ArticleColumn } from '@/types/article-column.type';
import type { ArticleFilter } from '@/types/article-filter.type';
import type { ArticleStatus } from '@/types/article-status.enum';
import type { Article } from '@/types/article.type';
import type { ChartDataEntry } from '@/types/chart-data-entry.interface';
import type { ChartGroupType } from '@/types/chart-group-type.enum';
import type { CleanGroup } from '@/types/clean-group.interface';
import type { ConclusionArticleColumn } from '@/types/conclusion-article-column.type';
import type { ConclusionArticleFilter } from '@/types/conclusion-article-filter.type';
import type { ConsolidateArticleColumn } from '@/types/consolidate-article-column.type';
import type { ConsolidateArticleFilter } from '@/types/consolidate-article-filter.type';
import type { Country } from '@/types/country.type';
import type { CreateUserEventRequestDTO } from '@/types/dto/create-user-event-request-dto.interface';
import type { CreateUserSettingsRequestDTO } from '@/types/dto/create-user-settings-request-dto.interface';
import type { CreateUserViewCommentRequestDTO } from '@/types/dto/create-user-view-comment-request-dto.interface';
import type { CreateUserViewRequestDTO } from '@/types/dto/create-user-view-request-dto.interface';
import type { UpdateUserSettingsRequestDTO } from '@/types/dto/update-user-settings-request-dto.interface';
import type { UpdateUserViewCommentRequestDTO } from '@/types/dto/update-user-view-comment-request-dto.interface';
import type { UpdateUserViewRequestDTO } from '@/types/dto/update-user-view-request-dto.interface';
import { Grouping } from '@/types/grouping.enum';
import type { KPIArticle } from '@/types/kpi-article.interface';
import type { Language } from '@/types/language.enum';
import type { ArticleDetails } from '@/types/models/article-details.interface';
import type { ConclusionArticle } from '@/types/models/conclusion-article.interface';
import type { ConsolidateArticle } from '@/types/models/consolidate-article.interface';
import type { ConsolidateSegment } from '@/types/models/consolidate-segment.interface';
import type { Spr } from '@/types/models/spr.interface';
import type { UserDetails } from '@/types/models/user-details.interface';
import type { UserSession } from '@/types/models/user-session.interface';
import type { UserSettings } from '@/types/models/user-settings.interface';
import type { UserViewChangeHistory } from '@/types/models/user-view-change-history.interface';
import type { UserViewCommentSeen } from '@/types/models/user-view-comment-seen.interface';
import type { UserViewComment } from '@/types/models/user-view-comment.interface';
import type { UserViewNotificationDetails } from '@/types/models/user-view-notification-details.interface';
import type { UserViewNotificationSeen } from '@/types/models/user-view-notification-seen.interface';
import type { UserView } from '@/types/models/user-view.interface';
import type { WhatsNew } from '@/types/models/whats-new.interface';
import type { Option } from '@/types/option.interface';
import type { PaginationResponse } from '@/types/pagination-response.interface';
import type { Pagination } from '@/types/pagination.interface';
import type { RangeGraphSegmentArticle } from '@/types/range-graph-segment-article.interface';
import type { Search } from '@/types/search.interface';
import type { Sorting } from '@/types/sorting.interface';
import type { TotalCleanArticlesGrouping } from '@/types/total-clean-articles-grouping.interface';
import type { TotalCleanArticles } from '@/types/total-clean-articles.interface';

const api = useAPI();

export const getI18n = (language: Language) => {
  const url = `/i18n/${language}.json`;
  return api.get<Record<string, string>>(url);
};

export const getWhatsNew = (): Promise<WhatsNew> => {
  const url = `${config.apiProductsEndpoint}/api/v1/products/whats-new`;
  return api.get<WhatsNew>(url);
};

export const getRetailUnitCodes = (accessToken: string) => {
  const url = `${config.apiProductsEndpoint}/api/v1/products/retail-unit-codes`;
  return api.get<string[]>(url, { customHeaders: { 'Access-Token': accessToken } });
};

export const getFiscalYears = (retailUnitCode: string) => {
  const query = buildQueryString({ retailUnitCode });
  const url = `${config.apiProductsEndpoint}/api/v1/products/fiscal-years?${query}`;
  return api.get<string[]>(url);
};

export const getFiscalYearsDescriptions = (retailUnitCode: string, fiscalYear: string) => {
  const query = buildQueryString({ retailUnitCode, fiscalYear });
  const url = `${config.apiProductsEndpoint}/api/v1/products/fiscal-years-descriptions?${query}`;
  return api.get<string[]>(url);
};

export const getCountries = (): Promise<Country[]> => {
  return api.get<Country[]>(`/data/countries.json`);
};

export const getEmojis = (): Promise<string[][]> => {
  return api.get<string[][]>(`/data/emojis.json`);
};

export const getGroupingOptions = (retailUnitCode: string, fiscalYear: string, grouping: Grouping) => {
  const query = buildQueryString({ retailUnitCode, fiscalYear, grouping });
  const url = `${config.apiProductsEndpoint}/api/v1/products/grouping-options?${query}`;
  return api.get<Option[]>(url);
};

export const getTotalCleanArticles = ({
  retailUnitCode,
  fiscalYear,
  nestingLevelGrouping,
  groupings,
  fivePercentRankingTail,
  tenPercentRankingTail,
  autoDetectRankingTail,
  excludedArticlesIds,
}: {
  retailUnitCode: string;
  fiscalYear: string;
  nestingLevelGrouping: Grouping | null;
  groupings: TotalCleanArticlesGrouping[];
  fivePercentRankingTail: boolean | null;
  tenPercentRankingTail: boolean | null;
  autoDetectRankingTail: boolean | null;
  excludedArticlesIds?: string[];
}) => {
  const query = buildQueryString({
    retailUnitCode,
    fiscalYear,
    nestingLevelGrouping,
    fivePercentRankingTail,
    tenPercentRankingTail,
    autoDetectRankingTail,
  });
  const url = `${config.apiProductsEndpoint}/api/v1/products/total-clean-articles?${query}`;

  return api.post<TotalCleanArticles[]>(url, {
    total_clean_articles_groupings: groupings,
    excluded_articles_ids: excludedArticlesIds,
  });
};

export const getArticleDetails = (articleNumber: string, retailUnitCode: string, fiscalYear: string) => {
  const query = buildQueryString({ articleNumber, retailUnitCode, fiscalYear });
  const url = `${config.apiProductsEndpoint}/api/v1/products/article-details?${query}`;
  return api.get<ArticleDetails>(url);
};

export const getArticleSPRs = (retailUnitCode: string, articleNumber: string) => {
  const query = buildQueryString({ retailUnitCode, articleNumber });
  const url = `${config.apiProductsEndpoint}/api/v1/products/article-sprs?${query}`;
  return api.get<Spr[]>(url);
};

export const getKPIArticles = (retailUnitCode: string, fiscalYear: string) => {
  const query = buildQueryString({ retailUnitCode, fiscalYear });
  const url = `${config.apiProductsEndpoint}/api/v1/products/kpi-articles?${query}`;
  return api.get<KPIArticle[]>(url);
};

export const getGroups = ({
  retailUnitCode,
  fiscalYear,
  nestingLevelGrouping,
  grouping,
  groupNo,
  fivePercentRankingTail,
  tenPercentRankingTail,
  autoDetectRankingTail,
  excludedArticlesIds,
}: {
  retailUnitCode: string;
  fiscalYear: string;
  nestingLevelGrouping: Grouping | null;
  grouping: Grouping;
  groupNo: string | null;
  fivePercentRankingTail: boolean | null;
  tenPercentRankingTail: boolean | null;
  autoDetectRankingTail: boolean | null;
  excludedArticlesIds?: string[];
}) => {
  const query = buildQueryString({
    retailUnitCode,
    fiscalYear,
    nestingLevelGrouping,
    grouping,
    groupNo,
    fivePercentRankingTail,
    tenPercentRankingTail,
    autoDetectRankingTail,
  });
  const url = `${config.apiProductsEndpoint}/api/v1/products/groups?${query}`;

  return api.post<CleanGroup[]>(url, excludedArticlesIds);
};

export const getChartsData = ({
  retailUnitCode,
  fiscalYear,
  nestingLevelGrouping,
  grouping,
  groupNo,
  chartGroupTypes,
  fivePercentRankingTail,
  tenPercentRankingTail,
  autoDetectRankingTail,
  excludedArticlesIds,
}: {
  retailUnitCode: string;
  fiscalYear: string;
  nestingLevelGrouping: Grouping | null;
  grouping: Grouping;
  groupNo: string | null;
  chartGroupTypes: ChartGroupType[];
  fivePercentRankingTail: boolean | null;
  tenPercentRankingTail: boolean | null;
  autoDetectRankingTail: boolean | null;
  excludedArticlesIds?: string[];
}) => {
  const query = buildQueryString({
    retailUnitCode,
    fiscalYear,
    nestingLevelGrouping,
    grouping,
    groupNo,
    fivePercentRankingTail,
    tenPercentRankingTail,
    autoDetectRankingTail,
  });
  const url = `${config.apiProductsEndpoint}/api/v1/products/chart-data?${query}`;
  const chartGroupRequestUrls = chartGroupTypes.map(
    (chartGroupType) => `${url}&chart_group_type=${encodeURIComponent(chartGroupType)}`,
  );

  return Promise.all(chartGroupRequestUrls.map((url) => api.post<ChartDataEntry[]>(url, excludedArticlesIds)));
};

export const getTableArticles = ({
  retailUnitCode,
  fiscalYear,
  nestingLevelGrouping,
  fivePercentRankingTail,
  tenPercentRankingTail,
  autoDetectRankingTail,
  pagination,
  sorting,
  search,
  filters,
  excludedArticlesIds,
}: {
  retailUnitCode: string;
  fiscalYear: string;
  nestingLevelGrouping: Grouping | null;
  fivePercentRankingTail: boolean | null;
  tenPercentRankingTail: boolean | null;
  autoDetectRankingTail: boolean | null;
  pagination?: Pagination;
  sorting?: Sorting<ArticleColumn>;
  search?: Search<ArticleColumn>;
  filters?: ArticleFilter[];
  excludedArticlesIds?: string[];
}) => {
  const query = buildQueryString({
    retailUnitCode,
    fiscalYear,
    nestingLevelGrouping,
    fivePercentRankingTail,
    tenPercentRankingTail,
    autoDetectRankingTail,
  });
  const url = `${config.apiProductsEndpoint}/api/v1/products/articles?${query}`;

  return api.post<PaginationResponse<Article>>(url, {
    pagination,
    sorting,
    search,
    filters,
    excluded_articles_ids: excludedArticlesIds,
  });
};

export const getExcludedTableArticles = ({
  retailUnitCode,
  fiscalYear,
  nestingLevelGrouping,
  fivePercentRankingTail,
  tenPercentRankingTail,
  autoDetectRankingTail,
  pagination,
  sorting,
  search,
  filters,
  excludedArticlesIds,
}: {
  retailUnitCode: string;
  fiscalYear: string;
  excludedArticlesIds: string[];
  nestingLevelGrouping: Grouping | null;
  fivePercentRankingTail: boolean | null;
  tenPercentRankingTail: boolean | null;
  autoDetectRankingTail: boolean | null;
  pagination?: Pagination;
  sorting?: Sorting<ArticleColumn>;
  search?: Search<ArticleColumn>;
  filters?: ArticleFilter[];
}) => {
  const query = buildQueryString({
    retailUnitCode,
    fiscalYear,
    nestingLevelGrouping,
    fivePercentRankingTail,
    tenPercentRankingTail,
    autoDetectRankingTail,
  });
  const url = `${config.apiProductsEndpoint}/api/v1/products/excluded-articles?${query}`;

  return api.post<PaginationResponse<Article>>(url, {
    pagination,
    sorting,
    search,
    filters,
    excluded_articles_ids: excludedArticlesIds,
  });
};

export const getTableFilterOptions = ({
  retailUnitCode,
  fiscalYear,
  column,
}: {
  retailUnitCode: string;
  fiscalYear: string;
  column: ArticleColumn;
}) => {
  const query = buildQueryString({ retailUnitCode, fiscalYear, column });
  const url = `${config.apiProductsEndpoint}/api/v1/products/filter-options?${query}`;

  return api.get<Option<string>[]>(url);
};

export const requestExcelExport = ({
  retailUnitCode,
  fiscalYear,
  nestingLevelGrouping,
  currency,
  currencyCode,
  articleFilters,
  fivePercentRankingTail,
  tenPercentRankingTail,
  autoDetectRankingTail,
  grouping,
  groupNoPath,
  excludedArticlesIds,
}: {
  retailUnitCode: string;
  fiscalYear: string;
  nestingLevelGrouping: Grouping | null;
  currency: string;
  currencyCode: string;
  articleFilters: ArticleFilter[];
  fivePercentRankingTail: boolean | null;
  tenPercentRankingTail: boolean | null;
  autoDetectRankingTail: boolean | null;
  grouping: Grouping;
  groupNoPath: string[] | null;
  excludedArticlesIds: string[];
}) => {
  const [hfbNo, paNo] = groupNoPath ?? [];
  const [functionalAreaNo, consumerFunctionNo, productFunctionNo] = groupNoPath ?? [];
  const filters: ArticleFilter[] = [
    ...articleFilters,
    ...(grouping === Grouping.HFB && hfbNo
      ? [
          {
            column: 'hfb_no',
            values: [hfbNo],
          } as ArticleFilter,
        ]
      : []),
    ...(grouping === Grouping.HFB && paNo
      ? [
          {
            column: 'pa_no',
            values: [paNo],
          } as ArticleFilter,
        ]
      : []),
    ...(grouping === Grouping.FUNCTIONAL_AREA && functionalAreaNo
      ? [
          {
            column: 'functional_area_name_no',
            values: [functionalAreaNo],
          } as ArticleFilter,
        ]
      : []),
    ...(grouping === Grouping.FUNCTIONAL_AREA && consumerFunctionNo
      ? [
          {
            column: 'consumer_function_name_no',
            values: [consumerFunctionNo],
          } as ArticleFilter,
        ]
      : []),
    ...(grouping === Grouping.FUNCTIONAL_AREA && productFunctionNo
      ? [
          {
            column: 'product_function_name_no',
            values: [productFunctionNo],
          } as ArticleFilter,
        ]
      : []),
  ];

  const query = buildQueryString({
    retailUnitCode,
    fiscalYear,
    nestingLevelGrouping,
    fivePercentRankingTail,
    tenPercentRankingTail,
    autoDetectRankingTail,
  });
  const url = `${config.apiProductsEndpoint}/api/v1/products/articles-excel?${query}`;

  return api.post<Blob>(
    url,
    {
      filters,
      currency,
      currency_code: currencyCode,
      excluded_articles_ids: excludedArticlesIds,
    },
    {
      responseType: 'blob',
    },
  );
};

export const createUserView = (dto: CreateUserViewRequestDTO) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view`;
  return api.post<UserView>(url, dto);
};

export const updateUserView = (dto: UpdateUserViewRequestDTO) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view`;
  return api.put<UserView>(url, dto);
};

export const deleteUserView = (viewId: string) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view?id=${viewId}`;
  return api.delete<void>(url);
};

export const getSharedUserViews = () => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/shared/list`;
  return api.get<UserView[]>(url);
};

export const getOwnedUserViews = () => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/owned/list`;
  return api.get<UserView[]>(url);
};

export const getUserViewChangeHistories = (viewId: string) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/history/list?view_id=${viewId}`;
  return api.get<UserViewChangeHistory[]>(url);
};

export const createUserViewComment = (dto: CreateUserViewCommentRequestDTO) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/comment`;
  return api.post<UserViewComment>(url, dto);
};

export const updateUserViewComment = (dto: UpdateUserViewCommentRequestDTO) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/comment`;
  return api.put<UserViewComment>(url, dto);
};

export const deleteUserViewComment = (viewId: string, sectionId: string, commentId: string) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/comment?view_id=${viewId}&section_id=${sectionId}&comment_id=${commentId}`;
  return api.delete<void>(url);
};

export const getUserViewComments = (viewId: string, sectionId: string) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/comment/list?view_id=${viewId}&section_id=${sectionId}`;
  return api.get<UserViewComment[]>(url);
};

export const getUserViewCommentsSeen = (viewId: string, sectionId: string) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/comment/seen/list?view_id=${viewId}&section_id=${sectionId}`;
  return api.get<UserViewCommentSeen[]>(url);
};

export const getUserViewNotifications = (viewId: string) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/notification/list?view_id=${viewId}`;
  return api.get<UserViewNotificationDetails[]>(url);
};

export const getUserViewNotificationsSeen = (viewId: string) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/view/notification/seen/list?view_id=${viewId}`;
  return api.get<UserViewNotificationSeen[]>(url);
};

export const getRangeGraphSegmentArticles = ({
  retailUnitCode,
  fiscalYear,
  nestingLevelGrouping,
  grouping,
  groupNo,
  articleStatus,
  fivePercentRankingTail,
  tenPercentRankingTail,
  autoDetectRankingTail,
  excludedArticlesIds,
}: {
  retailUnitCode: string;
  fiscalYear: string;
  nestingLevelGrouping: Grouping | null;
  grouping: Grouping;
  groupNo: string;
  articleStatus: ArticleStatus;
  fivePercentRankingTail: boolean | null;
  tenPercentRankingTail: boolean | null;
  autoDetectRankingTail: boolean | null;
  excludedArticlesIds?: string[];
}) => {
  const query = buildQueryString({
    retailUnitCode,
    fiscalYear,
    nestingLevelGrouping,
    grouping,
    groupNo,
    articleStatus,
    fivePercentRankingTail,
    tenPercentRankingTail,
    autoDetectRankingTail,
  });
  const url = `${config.apiProductsEndpoint}/api/v1/products/groups/segment?${query}`;

  return api.post<RangeGraphSegmentArticle[]>(url, excludedArticlesIds);
};

export const getUserSessions = () => {
  const url = `${config.apiUserEndpoint}/api/v1/user/session/list`;
  return api.get<UserSession[]>(url);
};

export const createUserSettings = (dto: CreateUserSettingsRequestDTO) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/settings`;
  return api.post<UserSettings>(url, dto);
};

export const updateUserSettings = (dto: UpdateUserSettingsRequestDTO) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/settings`;
  return api.put<UserSettings>(url, dto);
};

export const getUserSettings = () => {
  const url = `${config.apiUserEndpoint}/api/v1/user/settings`;
  return api.get<UserSettings>(url);
};

export const getUsersDetailsByOwnerNameOrEmail = (ownerNameOrEmail: string) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/user-details/search?owner_name_or_email=${ownerNameOrEmail}`;
  return api.get<UserDetails[]>(url);
};

export const getUsersDetails = () => {
  const url = `${config.apiUserEndpoint}/api/v1/user/user-details/list`;
  return api.get<UserDetails[]>(url);
};

export const createUserEvent = (dto: CreateUserEventRequestDTO) => {
  const url = `${config.apiUserEndpoint}/api/v1/user/analytics`;
  return api.post<boolean>(url, dto);
};

export const getConsolidateSegments = () => {
  const url = `${config.apiProductsEndpoint}/api/v1/products/consolidate/segments`;
  return api.get<ConsolidateSegment[]>(url);
};

export const getConsolidateTableArticles = ({
  pagination,
  sorting,
  search,
  filters,
  excludedArticlesIds,
}: {
  pagination?: Pagination;
  sorting?: Sorting<ConsolidateArticleColumn>;
  search?: Search<ConsolidateArticleColumn>;
  filters?: ConsolidateArticleFilter[];
  excludedArticlesIds: string[];
}) => {
  const url = `${config.apiProductsEndpoint}/api/v1/products/consolidate/articles`;

  return api.post<PaginationResponse<ConsolidateArticle>>(url, {
    pagination,
    sorting,
    search,
    filters,
    excluded_articles_ids: excludedArticlesIds,
  });
};

export const getConclusionTableArticles = ({
  retailUnitCode,
  fiscalYear,
  nestingLevelGrouping,
  fivePercentRankingTail,
  tenPercentRankingTail,
  autoDetectRankingTail,
  pagination,
  sorting,
  search,
  filters,
  includedArticlesIds,
  excludedArticlesIds,
}: {
  retailUnitCode: string;
  fiscalYear: string;
  nestingLevelGrouping: Grouping | null;
  fivePercentRankingTail: boolean | null;
  tenPercentRankingTail: boolean | null;
  autoDetectRankingTail: boolean | null;
  pagination?: Pagination;
  sorting?: Sorting<ConclusionArticleColumn>;
  search?: Search<ConclusionArticleColumn>;
  filters?: ConclusionArticleFilter[];
  includedArticlesIds: string[];
  excludedArticlesIds: string[];
}) => {
  const query = buildQueryString({
    retailUnitCode,
    fiscalYear,
    nestingLevelGrouping,
    fivePercentRankingTail,
    tenPercentRankingTail,
    autoDetectRankingTail,
  });
  const url = `${config.apiProductsEndpoint}/api/v1/products/conclusion/articles?${query}`;

  return api.post<PaginationResponse<ConclusionArticle>>(url, {
    pagination,
    sorting,
    search,
    filters,
    included_articles_ids: includedArticlesIds,
    excluded_articles_ids: excludedArticlesIds,
  });
};

export const getIncludedConsolidateTableArticles = ({
  pagination,
  sorting,
  search,
  filters,
  includedArticlesIds,
}: {
  pagination?: Pagination;
  sorting?: Sorting<ConsolidateArticleColumn>;
  search?: Search<ConsolidateArticleColumn>;
  filters?: ConsolidateArticleFilter[];
  includedArticlesIds: string[];
}) => {
  const url = `${config.apiProductsEndpoint}/api/v1/products/consolidate/included-articles`;

  return api.post<PaginationResponse<ConsolidateArticle>>(url, {
    pagination,
    sorting,
    search,
    filters,
    included_articles_ids: includedArticlesIds,
  });
};
