export class HTTPException extends Error {
  constructor(
    public status: number,
    message?: string,
  ) {
    super(message);
  }
}

export enum StatusCode {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
}

export class BadRequestException extends HTTPException {
  constructor(message?: string) {
    super(StatusCode.BAD_REQUEST, message);
  }
}

export class UnauthorizedException extends HTTPException {
  constructor(message?: string) {
    super(StatusCode.UNAUTHORIZED, message);
  }
}

export class ForbiddenException extends HTTPException {
  constructor(message?: string) {
    super(StatusCode.FORBIDDEN, message);
  }
}

export class NotFoundException extends HTTPException {
  constructor(message?: string) {
    super(StatusCode.NOT_FOUND, message);
  }
}

export class ConflictException extends HTTPException {
  constructor(message?: string) {
    super(StatusCode.CONFLICT, message);
  }
}

export const tryExtractingException = async (response: Response): Promise<HTTPException | null> => {
  if (response.status === StatusCode.OK) {
    return null;
  }

  let message: string;

  try {
    const { detail } = await response.json();
    message = detail;
  } catch {
    message = response.statusText;
  }

  switch (response.status) {
    case StatusCode.BAD_REQUEST:
      return new BadRequestException(message);

    case StatusCode.UNAUTHORIZED:
      return new UnauthorizedException(message);

    case StatusCode.FORBIDDEN:
      return new ForbiddenException(message);

    case StatusCode.NOT_FOUND:
      return new NotFoundException(message);

    case StatusCode.CONFLICT:
      return new ConflictException(message);

    default:
      return new HTTPException(response.status, message);
  }
};

export const performGlobalErrorHandling = (error: unknown): void => {
  if (error instanceof HTTPException) {
    console.error('API returned with status code:', error.status);

    if (error.message) {
      console.error('Message:', error.message);
    }
  } else {
    console.error('Error making request:', error);
  }
};
