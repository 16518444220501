import * as API from '@/api/api';
import { EntityMutationType } from '@/types/entity-mutation-type.enum';
import type { UserView } from '@/types/models/user-view.interface';
import { defineStore } from 'pinia';
import { useWebSocketsStore } from '../web-sockets/web-sockets.store';
import type { UserViewStoreActions, UserViewStoreGetters, UserViewStoreState } from './user-view-store.types';

export const useUserViewStore = defineStore<'userView', UserViewStoreState, UserViewStoreGetters, UserViewStoreActions>(
  'userView',
  {
    state: () => ({
      sharedUserViewsIds: new Set(),
      sharedUserViewsLoading: false,
      sharedUserViewsLoaded: false,
      ownedUserViewsIds: new Set(),
      ownedUserViewsLoading: false,
      ownedUserViewsLoaded: false,
      loadingByUserViewId: {},
    }),
    getters: {
      sharedUserViews(): UserView[] {
        const webSocketsStore = useWebSocketsStore();

        return [...this.sharedUserViewsIds]
          .filter((id) => webSocketsStore.userViewById[id])
          .map((id) => webSocketsStore.userViewById[id]!);
      },
      ownedUserViews(): UserView[] {
        const webSocketsStore = useWebSocketsStore();

        return [...this.ownedUserViewsIds]
          .filter((id) => webSocketsStore.userViewById[id])
          .map((id) => webSocketsStore.userViewById[id]!);
      },
    },
    actions: {
      processEntityMutation(userId, userEmail, entityMutation): void {
        const isOwnedView = (userView: UserView) => userView.owner_id === userId;
        const isSharedView = (userView: UserView) => userView.members.some((member) => member.email === userEmail);

        switch (entityMutation.mutation_type) {
          case EntityMutationType.USER_VIEW_CREATED:
            if (isOwnedView(entityMutation.entity)) {
              this.ownedUserViewsIds.add(entityMutation.entity.id);
            } else if (isSharedView(entityMutation.entity)) {
              this.sharedUserViewsIds.add(entityMutation.entity.id);
            }
            break;
        }
      },

      async createUserView({ name, description, state, members }): Promise<UserView> {
        const webSocketsStore = useWebSocketsStore();

        this.ownedUserViewsLoading = true;

        const userView = await API.createUserView({
          name,
          description,
          state,
          members,
        });

        webSocketsStore.userViewById[userView.id] = userView;
        this.ownedUserViewsIds.add(userView.id);

        this.ownedUserViewsLoading = false;

        return userView;
      },

      async updateUserView({ viewId, name, description, state, members }): Promise<void> {
        const webSocketsStore = useWebSocketsStore();

        const existingUserView = webSocketsStore.userViewById[viewId];

        // Store the previous state to revert in case of failure
        const previousState = { ...existingUserView! };

        // Apply the optimistic update
        const updatedAt = new Date().toISOString();
        webSocketsStore.userViewById[viewId] = {
          ...existingUserView!,
          ...(name ? { name } : {}),
          description,
          ...(state ? { state } : {}),
          ...(members ? { members } : {}),
          updated_at: updatedAt,
        };

        this.loadingByUserViewId[viewId] = true;

        if (this.sharedUserViewsIds.has(viewId)) {
          this.sharedUserViewsLoading = true;
        } else if (this.ownedUserViewsIds.has(viewId)) {
          this.ownedUserViewsLoading = true;
        }

        try {
          const userView = await API.updateUserView({
            view_id: viewId,
            name,
            description,
            state,
            members,
          });

          // Update the store with the response from the server
          // Only if the server's updated_at is newer
          if (new Date(userView.updated_at) > new Date(updatedAt)) {
            webSocketsStore.userViewById[userView.id] = userView;
          }
        } catch (error) {
          // Revert to the previous state in case of an error
          webSocketsStore.userViewById[viewId] = previousState;
          // Optionally show an error message to the user
          throw error;
        } finally {
          this.loadingByUserViewId[viewId] = false;

          if (this.sharedUserViewsIds.has(viewId)) {
            this.sharedUserViewsLoading = false;
          } else if (this.ownedUserViewsIds.has(viewId)) {
            this.ownedUserViewsLoading = false;
          }
        }
      },
      async deleteUserView(viewId): Promise<void> {
        const webSocketsStore = useWebSocketsStore();

        this.loadingByUserViewId[viewId] = true;
        this.ownedUserViewsLoading = true;

        await API.deleteUserView(viewId);

        this.ownedUserViewsIds.delete(viewId);
        delete webSocketsStore.userViewById[viewId];

        this.loadingByUserViewId[viewId] = false;
        this.ownedUserViewsLoading = false;
      },

      async fetchSharedUserViews(): Promise<void> {
        if (this.sharedUserViewsLoaded || this.sharedUserViewsLoading) {
          return;
        }

        const webSocketsStore = useWebSocketsStore();

        this.sharedUserViewsLoading = true;

        const sharedUserViews = await API.getSharedUserViews();

        this.sharedUserViewsIds = new Set(sharedUserViews.map(({ id }) => id));
        webSocketsStore.updateUserViewById(sharedUserViews);

        this.sharedUserViewsLoading = false;
        this.sharedUserViewsLoaded = true;
      },

      async fetchOwnedUserViews(): Promise<void> {
        if (this.ownedUserViewsLoaded || this.ownedUserViewsLoading) {
          return;
        }

        const webSocketsStore = useWebSocketsStore();

        this.ownedUserViewsLoading = true;

        const ownedUserViews = await API.getOwnedUserViews();

        this.ownedUserViewsIds = new Set(ownedUserViews.map(({ id }) => id));
        webSocketsStore.updateUserViewById(ownedUserViews);

        this.ownedUserViewsLoading = false;
        this.ownedUserViewsLoaded = true;
      },
    },
  },
);
