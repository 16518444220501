import * as API from '@/api/api';
import { DEFAULT_USER_SETTINGS_STATE } from '@/constants';
import { HTTPException } from '@/hooks/use-error-handling.hook';
import type { UserSettingsState } from '@/types/models/user-settings-state.interface';
import type { UserSettings } from '@/types/models/user-settings.interface';
import { defineStore } from 'pinia';
import type { UserSettingsStoreActions, UserSettingsStoreState } from './user-settings-store.types';

export const useUserSettingsStore = defineStore<
  'userSettings',
  UserSettingsStoreState,
  Record<string, never>,
  UserSettingsStoreActions
>('userSettings', {
  state: () => ({
    state: {
      tail: {
        tableIncludedArticlesIds: DEFAULT_USER_SETTINGS_STATE.tail.tableIncludedArticlesIds,
        tableExcludedArticlesIds: DEFAULT_USER_SETTINGS_STATE.tail.tableExcludedArticlesIds,
        tablePinnedHeaders: DEFAULT_USER_SETTINGS_STATE.tail.tablePinnedHeaders,
        tableHiddenHeaders: DEFAULT_USER_SETTINGS_STATE.tail.tableHiddenHeaders,
        tableOrderedHeaders: DEFAULT_USER_SETTINGS_STATE.tail.tableOrderedHeaders,
        tableArticleStatusValues: DEFAULT_USER_SETTINGS_STATE.tail.tableArticleStatusValues,
        tableCCLValues: DEFAULT_USER_SETTINGS_STATE.tail.tableCCLValues,
        tableStyleGroupValues: DEFAULT_USER_SETTINGS_STATE.tail.tableStyleGroupValues,
        tablePriceLevelValues: DEFAULT_USER_SETTINGS_STATE.tail.tablePriceLevelValues,
        tableProductNameValues: DEFAULT_USER_SETTINGS_STATE.tail.tableProductNameValues,
        tablePage: DEFAULT_USER_SETTINGS_STATE.tail.tablePage,
        tableItemsPerPage: DEFAULT_USER_SETTINGS_STATE.tail.tableItemsPerPage,
        tableOrderBy: DEFAULT_USER_SETTINGS_STATE.tail.tableOrderBy,
        tableDesc: DEFAULT_USER_SETTINGS_STATE.tail.tableDesc,
        tableSearchColumn: DEFAULT_USER_SETTINGS_STATE.tail.tableSearchColumn,
        tableSearchValue: DEFAULT_USER_SETTINGS_STATE.tail.tableSearchValue,
        tableColoring: DEFAULT_USER_SETTINGS_STATE.tail.tableColoring,
      },
      consolidate: {
        tableIncludedArticlesIds: DEFAULT_USER_SETTINGS_STATE.consolidate.tableIncludedArticlesIds,
        tableExcludedArticlesIds: DEFAULT_USER_SETTINGS_STATE.consolidate.tableExcludedArticlesIds,
        tablePinnedHeaders: DEFAULT_USER_SETTINGS_STATE.consolidate.tablePinnedHeaders,
        tableHiddenHeaders: DEFAULT_USER_SETTINGS_STATE.consolidate.tableHiddenHeaders,
        tableOrderedHeaders: DEFAULT_USER_SETTINGS_STATE.consolidate.tableOrderedHeaders,
        tableArticleStatusValues: DEFAULT_USER_SETTINGS_STATE.consolidate.tableArticleStatusValues,
        tableCCLValues: DEFAULT_USER_SETTINGS_STATE.consolidate.tableCCLValues,
        tableStyleGroupValues: DEFAULT_USER_SETTINGS_STATE.consolidate.tableStyleGroupValues,
        tablePriceLevelValues: DEFAULT_USER_SETTINGS_STATE.consolidate.tablePriceLevelValues,
        tableProductNameValues: DEFAULT_USER_SETTINGS_STATE.consolidate.tableProductNameValues,
        tablePage: DEFAULT_USER_SETTINGS_STATE.consolidate.tablePage,
        tableItemsPerPage: DEFAULT_USER_SETTINGS_STATE.consolidate.tableItemsPerPage,
        tableOrderBy: DEFAULT_USER_SETTINGS_STATE.consolidate.tableOrderBy,
        tableDesc: DEFAULT_USER_SETTINGS_STATE.consolidate.tableDesc,
        tableSearchColumn: DEFAULT_USER_SETTINGS_STATE.consolidate.tableSearchColumn,
        tableSearchValue: DEFAULT_USER_SETTINGS_STATE.consolidate.tableSearchValue,
        tableColoring: DEFAULT_USER_SETTINGS_STATE.consolidate.tableColoring,
      },
      conclusion: {
        tableIncludedArticlesIds: DEFAULT_USER_SETTINGS_STATE.conclusion.tableIncludedArticlesIds,
        tableExcludedArticlesIds: DEFAULT_USER_SETTINGS_STATE.conclusion.tableExcludedArticlesIds,
        tablePinnedHeaders: DEFAULT_USER_SETTINGS_STATE.conclusion.tablePinnedHeaders,
        tableHiddenHeaders: DEFAULT_USER_SETTINGS_STATE.conclusion.tableHiddenHeaders,
        tableOrderedHeaders: DEFAULT_USER_SETTINGS_STATE.conclusion.tableOrderedHeaders,
        tableArticleStatusValues: DEFAULT_USER_SETTINGS_STATE.conclusion.tableArticleStatusValues,
        tableCCLValues: DEFAULT_USER_SETTINGS_STATE.conclusion.tableCCLValues,
        tableStyleGroupValues: DEFAULT_USER_SETTINGS_STATE.conclusion.tableStyleGroupValues,
        tablePriceLevelValues: DEFAULT_USER_SETTINGS_STATE.conclusion.tablePriceLevelValues,
        tableProductNameValues: DEFAULT_USER_SETTINGS_STATE.conclusion.tableProductNameValues,
        tablePage: DEFAULT_USER_SETTINGS_STATE.conclusion.tablePage,
        tableItemsPerPage: DEFAULT_USER_SETTINGS_STATE.conclusion.tableItemsPerPage,
        tableOrderBy: DEFAULT_USER_SETTINGS_STATE.conclusion.tableOrderBy,
        tableDesc: DEFAULT_USER_SETTINGS_STATE.conclusion.tableDesc,
        tableSearchColumn: DEFAULT_USER_SETTINGS_STATE.conclusion.tableSearchColumn,
        tableSearchValue: DEFAULT_USER_SETTINGS_STATE.conclusion.tableSearchValue,
        tableColoring: DEFAULT_USER_SETTINGS_STATE.conclusion.tableColoring,
      },
    },
  }),
  actions: {
    async createSettings(state): Promise<UserSettings> {
      Object.assign(this.state, state);

      return API.createUserSettings({
        state: JSON.stringify(this.state),
      });
    },

    async updateSettings({ tail, consolidate }): Promise<UserSettings> {
      Object.assign(this.state.tail, tail);
      Object.assign(this.state.consolidate, consolidate);

      return API.updateUserSettings({
        state: JSON.stringify(this.state),
      });
    },

    async fetchSettings(): Promise<void> {
      let settings: UserSettings;

      try {
        settings = await API.getUserSettings();
      } catch (err) {
        if (err instanceof HTTPException && err.status === 404) {
          settings = await this.createSettings(this.state);
        } else {
          console.error(err);
          return;
        }
      }

      const state: UserSettingsState = JSON.parse(settings.state);

      this.state.tail.tableExcludedArticlesIds =
        state.tail.tableExcludedArticlesIds ?? DEFAULT_USER_SETTINGS_STATE.tail.tableExcludedArticlesIds;
      this.state.tail.tablePinnedHeaders =
        state.tail.tablePinnedHeaders ?? DEFAULT_USER_SETTINGS_STATE.tail.tablePinnedHeaders;
      this.state.tail.tableHiddenHeaders =
        state.tail.tableHiddenHeaders ?? DEFAULT_USER_SETTINGS_STATE.tail.tableHiddenHeaders;
      this.state.tail.tableOrderedHeaders =
        state.tail.tableOrderedHeaders ?? DEFAULT_USER_SETTINGS_STATE.tail.tableOrderedHeaders;
      this.state.tail.tableArticleStatusValues =
        state.tail.tableArticleStatusValues ?? DEFAULT_USER_SETTINGS_STATE.tail.tableArticleStatusValues;
      this.state.tail.tableCCLValues = state.tail.tableCCLValues ?? DEFAULT_USER_SETTINGS_STATE.tail.tableCCLValues;
      this.state.tail.tableStyleGroupValues =
        state.tail.tableStyleGroupValues ?? DEFAULT_USER_SETTINGS_STATE.tail.tableStyleGroupValues;
      this.state.tail.tablePriceLevelValues =
        state.tail.tablePriceLevelValues ?? DEFAULT_USER_SETTINGS_STATE.tail.tablePriceLevelValues;
      this.state.tail.tableProductNameValues =
        state.tail.tableProductNameValues ?? DEFAULT_USER_SETTINGS_STATE.tail.tableProductNameValues;
      this.state.tail.tablePage = state.tail.tablePage ?? DEFAULT_USER_SETTINGS_STATE.tail.tablePage;
      this.state.tail.tableItemsPerPage =
        state.tail.tableItemsPerPage ?? DEFAULT_USER_SETTINGS_STATE.tail.tableItemsPerPage;
      this.state.tail.tableOrderBy = state.tail.tableOrderBy ?? DEFAULT_USER_SETTINGS_STATE.tail.tableOrderBy;
      this.state.tail.tableDesc = state.tail.tableDesc ?? DEFAULT_USER_SETTINGS_STATE.tail.tableDesc;
      this.state.tail.tableSearchColumn =
        state.tail.tableSearchColumn ?? DEFAULT_USER_SETTINGS_STATE.tail.tableSearchColumn;
      this.state.tail.tableSearchValue =
        state.tail.tableSearchValue ?? DEFAULT_USER_SETTINGS_STATE.tail.tableSearchValue;
      this.state.tail.tableColoring = state.tail.tableColoring ?? DEFAULT_USER_SETTINGS_STATE.tail.tableColoring;

      this.state.consolidate.tableIncludedArticlesIds =
        state.consolidate.tableIncludedArticlesIds ?? DEFAULT_USER_SETTINGS_STATE.consolidate.tableIncludedArticlesIds;
      this.state.consolidate.tablePinnedHeaders =
        state.consolidate.tablePinnedHeaders ?? DEFAULT_USER_SETTINGS_STATE.consolidate.tablePinnedHeaders;
      this.state.consolidate.tableHiddenHeaders =
        state.consolidate.tableHiddenHeaders ?? DEFAULT_USER_SETTINGS_STATE.consolidate.tableHiddenHeaders;
      this.state.consolidate.tableOrderedHeaders =
        state.consolidate.tableOrderedHeaders ?? DEFAULT_USER_SETTINGS_STATE.consolidate.tableOrderedHeaders;
      this.state.consolidate.tableArticleStatusValues =
        state.consolidate.tableArticleStatusValues ?? DEFAULT_USER_SETTINGS_STATE.consolidate.tableArticleStatusValues;
      this.state.consolidate.tableCCLValues =
        state.consolidate.tableCCLValues ?? DEFAULT_USER_SETTINGS_STATE.consolidate.tableCCLValues;
      this.state.consolidate.tableStyleGroupValues =
        state.consolidate.tableStyleGroupValues ?? DEFAULT_USER_SETTINGS_STATE.consolidate.tableStyleGroupValues;
      this.state.consolidate.tablePriceLevelValues =
        state.consolidate.tablePriceLevelValues ?? DEFAULT_USER_SETTINGS_STATE.consolidate.tablePriceLevelValues;
      this.state.consolidate.tableProductNameValues =
        state.consolidate.tableProductNameValues ?? DEFAULT_USER_SETTINGS_STATE.consolidate.tableProductNameValues;
      this.state.consolidate.tablePage =
        state.consolidate.tablePage ?? DEFAULT_USER_SETTINGS_STATE.consolidate.tablePage;
      this.state.consolidate.tableItemsPerPage =
        state.consolidate.tableItemsPerPage ?? DEFAULT_USER_SETTINGS_STATE.consolidate.tableItemsPerPage;
      this.state.consolidate.tableOrderBy =
        state.consolidate.tableOrderBy ?? DEFAULT_USER_SETTINGS_STATE.consolidate.tableOrderBy;
      this.state.consolidate.tableDesc =
        state.consolidate.tableDesc ?? DEFAULT_USER_SETTINGS_STATE.consolidate.tableDesc;
      this.state.consolidate.tableSearchColumn =
        state.consolidate.tableSearchColumn ?? DEFAULT_USER_SETTINGS_STATE.consolidate.tableSearchColumn;
      this.state.consolidate.tableSearchValue =
        state.consolidate.tableSearchValue ?? DEFAULT_USER_SETTINGS_STATE.consolidate.tableSearchValue;
      this.state.consolidate.tableColoring =
        state.consolidate.tableColoring ?? DEFAULT_USER_SETTINGS_STATE.consolidate.tableColoring;

      this.state.conclusion.tableIncludedArticlesIds =
        state.conclusion.tableIncludedArticlesIds ?? DEFAULT_USER_SETTINGS_STATE.conclusion.tableIncludedArticlesIds;
      this.state.conclusion.tablePinnedHeaders =
        state.conclusion.tablePinnedHeaders ?? DEFAULT_USER_SETTINGS_STATE.conclusion.tablePinnedHeaders;
      this.state.conclusion.tableHiddenHeaders =
        state.conclusion.tableHiddenHeaders ?? DEFAULT_USER_SETTINGS_STATE.conclusion.tableHiddenHeaders;
      this.state.conclusion.tableOrderedHeaders =
        state.conclusion.tableOrderedHeaders ?? DEFAULT_USER_SETTINGS_STATE.conclusion.tableOrderedHeaders;
      this.state.conclusion.tableArticleStatusValues =
        state.conclusion.tableArticleStatusValues ?? DEFAULT_USER_SETTINGS_STATE.conclusion.tableArticleStatusValues;
      this.state.conclusion.tableCCLValues =
        state.conclusion.tableCCLValues ?? DEFAULT_USER_SETTINGS_STATE.conclusion.tableCCLValues;
      this.state.conclusion.tableStyleGroupValues =
        state.conclusion.tableStyleGroupValues ?? DEFAULT_USER_SETTINGS_STATE.conclusion.tableStyleGroupValues;
      this.state.conclusion.tablePriceLevelValues =
        state.conclusion.tablePriceLevelValues ?? DEFAULT_USER_SETTINGS_STATE.conclusion.tablePriceLevelValues;
      this.state.conclusion.tableProductNameValues =
        state.conclusion.tableProductNameValues ?? DEFAULT_USER_SETTINGS_STATE.conclusion.tableProductNameValues;
      this.state.conclusion.tablePage = state.conclusion.tablePage ?? DEFAULT_USER_SETTINGS_STATE.conclusion.tablePage;
      this.state.conclusion.tableItemsPerPage =
        state.conclusion.tableItemsPerPage ?? DEFAULT_USER_SETTINGS_STATE.conclusion.tableItemsPerPage;
      this.state.conclusion.tableOrderBy =
        state.conclusion.tableOrderBy ?? DEFAULT_USER_SETTINGS_STATE.conclusion.tableOrderBy;
      this.state.conclusion.tableDesc = state.conclusion.tableDesc ?? DEFAULT_USER_SETTINGS_STATE.conclusion.tableDesc;
      this.state.conclusion.tableSearchColumn =
        state.conclusion.tableSearchColumn ?? DEFAULT_USER_SETTINGS_STATE.conclusion.tableSearchColumn;
      this.state.conclusion.tableSearchValue =
        state.conclusion.tableSearchValue ?? DEFAULT_USER_SETTINGS_STATE.conclusion.tableSearchValue;
      this.state.conclusion.tableColoring =
        state.conclusion.tableColoring ?? DEFAULT_USER_SETTINGS_STATE.conclusion.tableColoring;
    },
  },
});
