import { useIDTokenStore } from '@/store/id-token/id-token.store';
import { createPinia, setActivePinia } from 'pinia';
import { performGlobalErrorHandling, tryExtractingException } from './use-error-handling.hook';

setActivePinia(createPinia());

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

type Options = {
  customHeaders?: HeadersInit;
  customOptions?: RequestInit;
  responseType?: XMLHttpRequestResponseType;
};

type API = {
  get: <T>(url: string, options?: Options) => Promise<T>;
  post: <T>(url: string, body: unknown, options?: Options) => Promise<T>;
  put: <T>(url: string, body: unknown, options?: Options) => Promise<T>;
  delete: <T>(url: string, options?: Options) => Promise<T>;
};

const get = <T>(url: string, options?: Options) => request<T>(url, 'GET', undefined, options);
const post = <T>(url: string, body: unknown, options?: Options) => request<T>(url, 'POST', body, options);
const put = <T>(url: string, body: unknown, options?: Options) => request<T>(url, 'PUT', body, options);
const del = <T>(url: string, options?: Options) => request<T>(url, 'DELETE', undefined, options);

const api: API = { get, post, put, delete: del };

export const useAPI = (): API => api;

// Generic API request wrapper
const request = async <T>(url: string, method: RequestMethod, body?: unknown, options?: Options): Promise<T> => {
  options = options || {};
  options.customHeaders = options.customHeaders || {};
  options.customOptions = options.customOptions || {};

  const { customHeaders, customOptions, responseType } = options;

  let data: T;

  try {
    const response = await fetch(url, buildOptions(method, body, customHeaders, customOptions));

    const exception = await tryExtractingException(response);

    if (exception) {
      throw exception;
    }

    if (responseType === 'blob') {
      data = (await response.blob()) as T;
    } else {
      data = await response.json();
    }
  } catch (error) {
    performGlobalErrorHandling(error);
    throw error;
  }

  return data;
};

// Options builder
const buildOptions = (
  method: RequestMethod,
  body: unknown,
  customHeaders: HeadersInit,
  customOptions: RequestInit,
): RequestInit => {
  const idTokenStore = useIDTokenStore();
  const defaultHeaders = { 'Content-Type': 'application/json' };
  const defaultOptions = { method, ...((method === 'POST' || method === 'PUT') && { body: JSON.stringify(body) }) };

  const { token } = idTokenStore;
  if (token) {
    Object.assign(defaultHeaders, { Authentication: `Bearer ${token}` });
  }

  const headers = { ...defaultHeaders, ...customHeaders };
  const options = { ...defaultOptions, ...customOptions, headers: headers };

  return options;
};
